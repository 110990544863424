<template>
  <OnFooter />
  <footer class="footer">
    <section class="">
      <!-- Footer -->
      <div class="text-white text-center">
        <!-- Grid container -->
        <div class="container p-4 pt-0">
          <!--Grid row-->
          <div class="row">
            <!--Grid column-->
            <div class="col-12 mb-4 mb-md-0">
              <h3 class="text-uppercase">Nubetec</h3>

              <p class="text-center">
                Votre partenaire de confiance pour des solutions
                <strong>Cool Roof</strong> de qualité supérieure en France. Nous
                sommes spécialisés dans la mise en place de systèmes de toiture
                innovants et durables, conçus pour améliorer l'<strong
                  >efficacité énergétique</strong
                >
                de votre bâtiment tout en réduisant votre
                <strong>empreinte environnementale</strong>.
              </p>
            </div>
            <!--Grid column-->
          </div>
          <!--Grid row-->
        </div>
      </div>
      <!-- Footer -->
    </section>
    <!-- Footer -->
    <section class="text-center text-white footer">
      <ContactCard />
      <!-- Grid container -->
      <div class="container p-4 pb-2">
        <!-- Section: CTA -->
        <section class="mb-2">
          <a
            href="/devis"
            class="d-flex text-white justify-content-center align-items-center"
          >
            <span class="me-3">Demandez votre devis</span>
            <button type="button" class="btn btn-outline-light btn-rounded">
              Cool Roof
            </button>
          </a>
        </section>
        <!-- Section: CTA -->
      </div>
      <!-- Grid container -->

      <!-- Copyright -->
      <div class="text-center p-3 sub-footer">
        <a href="/mentions">© 2023 Copyright: NUBETEC</a>
        <a href="https://malaweb.fr/">Powered by Malaweb</a>
      </div>
      <!-- Copyright -->
    </section>
    <!-- Footer -->
  </footer>
</template>

<script>
import OnFooter from "@/components/partials/OnFooter.vue";
import ContactCard from "@/components/partials/ContactCard.vue";
export default {
  name: "FooterBase",
  components: { OnFooter, ContactCard },
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $color-darker;
  & .sub-footer {
    background-color: $color-primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & a {
      color: $color-text-light;
      font-weight: bolder;
    }
  }
  & h3 {
    font-weight: 200;
    color: $color-text-light;
    font-size: 2em;
    margin-bottom: 30px;
  }
}
</style>
