import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import MentionLegal from "@/views/MentionLegal.vue";
import HomeView from "@/views/HomeView.vue";
import DevisView from "@/views/DevisView.vue";
import ServicesView from "@/views/ServicesView.vue";
import ContactView from "@/views/ContactView.vue";
import BlogView from "@/views/BlogView.vue";
import LoginView from "@/views/LoginView.vue";
/*import RegisterView from "@/views/RegisterView.vue";*/
import PostView from "@/views/PostView.vue";
import ProfileView from "@/views/ProfileView.vue";
import EditPostView from "@/views/EditPostView.vue";
import CreatePostView from "@/views/CreatePostView.vue";
import DashBoard from "@/views/malazone/DashBoard.vue";
import MalaUsers from "@/views/malazone/MalaUsers.vue";
import MalaCats from "@/views/malazone/MalaCats.vue";
import MalaTags from "@/views/malazone/MalaTags.vue";
import MalaPosts from "@/views/malazone/MalaPosts.vue";
import MalaMedias from "@/views/malazone/MalaMedias.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cool-roof",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/cool-roof-france",
    name: "services",
    component: ServicesView,
  },
  {
    path: "/devis",
    name: "devis",
    component: DevisView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/mentions",
    name: "mentions",
    component: MentionLegal,
  },

  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { guest: true },
  },
  /*{
    path: "/register",
    name: "Register",
    component: RegisterView,
    meta: { guest: true },
  },*/
  {
    path: "/update/:slug",
    name: "edit",
    component: EditPostView,
  },
  {
    path: "/new-article",
    name: "create",
    component: CreatePostView,
  },
  {
    path: "/article/:slug",
    name: "article",
    component: PostView,
  },
  {
    path: "/profile/:pseudo",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/nubezone",
    name: "malazone",
    component: DashBoard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/users",
    name: "mala-users",
    component: MalaUsers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/posts",
    name: "mala-posts",
    component: MalaPosts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/categories",
    name: "mala-cats",
    component: MalaCats,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/tags",
    name: "mala-tags",
    component: MalaTags,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/malazone/medias",
    name: "mala-medias",
    component: MalaMedias,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  /*scrollBehavior() {
    return { x: 0, y: 0 };
  },*/
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/home");
      return;
    }
    next();
  } else {
    next();
  }
});
export default router;
