<template>
  <section>
    <div class="author-card container">
      <div class="author-card__id">
        <div class="rounded">
          <img
            :src="'https://api-blog.nubetec.fr/' + author.avatar.contentUrl"
            alt="picture-of-the-author"
          />
        </div>
        <div class="name">
          <p>{{ author.firstName }} {{ author.lastName }}</p>
          <p>{{ author.pseudo }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AuthorCard",
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  margin-bottom: 100px;
  & p:first-child {
    display: inline-block;
    & .wrapper {
      border: 1px $color-darker solid;
    }
  }
}
.author-card {
  display: flex;
  flex-direction: column;
  &__id {
    display: flex;
    justify-content: center;
    align-items: center;
    & .rounded {
      & img {
        margin-right: 10px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
      }
    }
    & .name {
      & p:first-child {
        font-size: 1.5em;
      }
      & p:nth-child(2) {
        font-size: 1em;
      }
    }
  }
}
</style>
