export const formatDate = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateTimeString);
  return date.toLocaleDateString("fr-FR", options);
};

// Effet d'apparition
export function observeElements(...classNames) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("active");
        observer.unobserve(entry.target);
      }
    });
  });

  classNames.forEach((className) => {
    const elements = document.querySelectorAll(className);
    elements.forEach((element) => {
      observer.observe(element);
    });
  });
}
