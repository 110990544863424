<template>
  <div class="container my-5">
    <section class="dark-grey-text pb-5">
      <h2 class="text-left font-weight-bold mb-4 pb-2">Le confort thermique</h2>
      <p class="text-left mx-auto w-responsive mb-5">
        <strong>Diminuer la température</strong> à l’intérieur des bâtiments
        améliore la sensation de bien-être. Que ce soit pour vos clients, vos
        collaborateurs ou votre matériel, un
        <strong>bâtiment frais</strong> sera un atout dans votre productivité.
        NUBETEC vous accompagne dans votre démarche de
        <strong>rafraîchissement passif</strong> avec l’application d’une
        <strong>peinture réflective</strong> et ainsi
        <strong>diminuer la température</strong> intérieure jusqu’à 6°C.
      </p>
      <div class="row align-items-center">
        <div class="col-lg-5 slide-left">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/robot.webp"
              alt="dock-cool-roofing-painting"
              class="img-fluid shadow"
            />
          </div>
        </div>
        <div class="col-lg-6 slide-right">
          <h3 class="mb-3">Le rafraîchissement passif</h3>
          <p>
            Le <strong>cool-roof</strong>ing fait partie des systèmes de
            <strong>rafraîchissement passif</strong>. Il permet de
            <strong>diminuer la température</strong> de votre bâtiment et de
            <strong>réduire l’impact environnemental</strong>.
          </p>
          <div>
            <button
              @click="moveTo(confort)"
              class="btn btn-success btn-md btn-rounded btn-gradient mx-0"
              name="btn-more"
              type="submit"
            >
              En savoir plus &raquo;
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="container pt-2 my-5">
    <section class="dark-grey-text">
      <div class="flex-end">
        <div class="col-lg-8">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <img
              src="@/assets/pictures/ilot-urbain.webp"
              alt="ilot-chaleur-urbain-draw"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-5 fall text-ilot">
          <h3 class="mb-3">Îlot de chaleur urbain</h3>
          <p>
            L’utilisation des climatisations pendant les fortes chaleurs de
            l’été, contribue à faire augmenter la température des villes. Un
            climatiseur va rejeter l’air chaud d’un bâtiment vers l’extérieur,
            on parle alors d’<strong>îlot de chaleur urbain</strong>.
          </p>
          <div class="rightBtn-gradient">
            <button
              @click.prevent="moveTo(ilot)"
              name="btn-more"
              type="submit"
              class="btn btn-success btn-md btn-rounded btn-gradient mx-0"
            >
              En savoir plus &raquo;
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeService",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left", ".fall");
    });
  },
  methods: {
    moveTo(anchorId) {
      this.$router
        .push({ path: "/cool-roof-france", hash: anchorId })
        .then(() => {
          // Faire défiler jusqu'à l'ancre après le changement de page
          this.$nextTick(() => {
            const anchor = document.getElementById(anchorId);
            if (anchor) {
              anchor.scrollIntoView({ behavior: "smooth" });
            }
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    & .text-ilot {
      margin-top: -14%;
      margin-left: -15%;
    }
  }
}
.rightBtn-gradient {
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: flex-start;
  }
}
.btn-gradient {
  background-color: $color-primary;
  border: 0.5px $color-text-light outset;
  margin-top: 10px;
  padding: 10px 20px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  @media screen and (min-width: 992px) {
    background: linear-gradient(to right, rgba(162, 193, 194, 0), #a2c1c2);
    border: none;
    padding: 10px 20px 10px 50px;
    cursor: pointer;
    transition: 1.5s;
    &:hover {
      background-color: $color-primary;
      border: 0.5px $color-text-light outset;
      padding: 10px 20px;
    }
  }
}
.fall.active {
  @include fall-down(1.2s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1s);
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
}
</style>
