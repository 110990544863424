<template>
  <div id="sticky-top" class="container-fluid sticky-top">
    <div
      class="row menu"
      :style="{
        background: changeColor ? 'rgb(86, 159, 174)' : 'rgb(255, 250, 250)',
      }"
    >
      <nav class="col navbar navbar-expand-lg navbar-light menu__nav">
        <router-link to="/" class="navbar-brand brand-name d-flex">
          <span v-if="changeColor">
            <img
              src="@/assets/brand/white-logo-nubetec.svg"
              alt="logo_nubetec_white"
              width="90"
              class="mx-lg-2"
            />
          </span>
          <span v-else>
            <img
              src="@/assets/brand/nav-nubetec.svg"
              alt="logo_nubetec_black"
              width="90"
              class="mx-lg-2"
            />
          </span>
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          name="burger-menu"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarContent" class="collapse navbar-collapse mx-5">
          <ul
            class="navbar nav ms-auto flex-row flex-sm-row mt-3 text-justify menu__ul"
          >
            <li class="nav-item" @click="closeMenu">
              <router-link
                to="/cool-roof"
                :style="{
                  color: changeColor ? 'rgb(255, 255, 255)' : 'rgb(18, 18, 18)',
                }"
                >Accueil</router-link
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item" @click="closeMenu">
              <router-link
                to="/cool-roof-france"
                :style="{
                  color: changeColor ? 'rgb(255, 255, 255)' : 'rgb(18, 18, 18)',
                }"
                >Le Cool Roof</router-link
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item" @click="closeMenu">
              <router-link
                to="/devis"
                :style="{
                  color: changeColor ? 'rgb(255, 255, 255)' : 'rgb(18, 18, 18)',
                }"
                >Devis</router-link
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item" @click="closeMenu">
              <router-link
                to="/contact"
                :style="{
                  color: changeColor ? 'rgb(255, 255, 255)' : 'rgb(18, 18, 18)',
                }"
                >Contact</router-link
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>

            <li class="nav-item" @click="closeMenu">
              <router-link
                to="/blog"
                :style="{
                  color: changeColor ? 'rgb(255, 255, 255)' : 'rgb(18, 18, 18)',
                }"
                >Actualités</router-link
              >
              <div id="arrow_up" class="arrow_up"></div>
            </li>
          </ul>
          <div class="text-center mt-4 mb-2">
            <FlipPhone />
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import FlipPhone from "@/components/partials/FlipPhone.vue";

export default {
  name: "NavBase",
  components: { FlipPhone },
  data() {
    return {
      changeColor: true,
      scrollPosition: null,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition > 100) {
        this.changeColor = false;
      } else {
        this.changeColor = true;
      }
    },
    closeMenu() {
      // fermer le menu en utilisant le sélecteur Bootstrap
      const toggle = document.querySelector(".navbar-toggler");
      const collapse = document.querySelector("#navbarContent");
      toggle.classList.remove("show");
      collapse.classList.remove("show");

      // déplacer la fenêtre du navigateur vers le haut de la page
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  background-color: black;
  padding: 3px 20px 3px 40px;
  & .brand-name {
    @media screen and (min-width: 992px) {
      margin: 5px 50px;
    }
  }
  & .brand-name:hover {
    & p {
      color: $color-text;
    }
    & span {
      color: $color-primary;
    }
  }
  & ul {
    margin-right: 15px;
  }
  &__span {
    color: $color-text;
  }
  &__ul {
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
  }
  & .nav-item {
    font-size: 1.1em;
    margin: 8px 2px;
  }
  &__nav {
    margin-bottom: 0px;
    & a:not(.brand-name) {
      color: $color-text-light;
      padding: 3px 10px;
      margin: 0px 5px;
      text-decoration: none;
      &.router-link-exact-active {
        background-color: $color-text;
        border-radius: 10px;
        color: $color-primary !important;
      }
    }
  }
}

.phone {
  border: 1px outset $color-primary;
  border-radius: 9%;
  font-size: 0.6em;
  margin-left: 20px;
  padding: 3px;
  & a {
    color: $color-primary;
    & a:hover {
      color: $color-text;
    }
  }
}
</style>
