<template>
  <HeaderBase
    anteTitle="Toute l'actualité"
    title="cool roof"
    :backgroundImage="require('@/assets/pictures/cool-roof.webp')"
  />
  <HeroBlog />
  <!-------------------------------TEST----------------------->
  <ArticleSelect :posts="Posts" />
  <!-------------------------------TEST----------------------->
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderBase from "@/components/HeaderBase.vue";
import ArticleSelect from "@/components/blog/ArticleSelect.vue";
import HeroBlog from "@/components/blog/HeroBlog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "BlogView",
  components: { HeaderBase, ArticleSelect, HeroBlog },
  setup() {
    useMeta({
      title: "L'actu cool roof",
      description: [
        {
          content:
            "Plongez dans le monde passionnant du Cool Roof avec notre blog informatif. Découvrez les avantages, les techniques et les conseils pour une toiture plus écologique et économe en énergie. Informez-vous et adoptez le Cool Roof dès aujourd'hui !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr/blog",
        },
      ],
    });
  },
  created() {
    // Appel initial à GetPosts() pour récupérer tous les articles du blog
    this.$store.dispatch("GetPostsPublished");
  },
  computed: {
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
    }),
  },
  methods: {
    ...mapActions(["GetPostsPublished", "GetPost"]),
    async showPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "article",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
