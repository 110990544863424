<template>
  <section class="plugin-categorie">
    <div class="container-fluid">
      <div class="row text-left pl-5">
        <p class="header-pluginCat">Sélectionner une catégorie :</p>
      </div>
      <div class="catList">
        <div v-for="category in AllCategory" :key="category.id" class="px-5">
          <input
            v-model="checkedCategory"
            @input="shareCategory"
            type="radio"
            :value="category"
            name="radioCategory"
            :id="category.id"
            class="input-hidden"
          />
          <label :for="category.id">
            <p>{{ category.name }}</p>
          </label>
        </div>
      </div>
    </div>
    <div class="text-center newcat pb-5">
      <form class="newcat" @submit.prevent="submitCategory">
        <p>Créer une nouvelle catégorie</p>
        <input
          type="text"
          id="category"
          name="category"
          v-model="newCategory"
        />
        <button type="submit" class="btn-primary">ajouter</button>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UploadCategory",
  components: {},
  created: function () {
    // a function to call getposts action
    this.GetAllCategory();
  },
  data() {
    return {
      newCategory: "",
      checkedCategory: "",
    };
  },
  computed: {
    ...mapGetters({
      AllCategory: "StateAllCategory",
    }),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  watch: {
    checkedCategory(newValue) {
      this.$emit("category-choice", newValue);
    },
  },
  methods: {
    ...mapActions(["CreateCategory", "GetAllCategory"]),
    async submitCategory() {
      try {
        let formData = new FormData();
        formData.append("name", this.newCategory);
        formData = Object.fromEntries(formData.entries());
        await this.CreateCategory(formData);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    shareCategory() {
      this.$emit("category-choice", this.checkedCategory);
    },
  },
};
</script>

<style lang="scss" scoped>
.plugin-categorie {
  & .header-pluginCat {
    text-align: center;
    padding: 20px 20px 0px 20px;
  }
  & .catList {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 90vw;
    overflow: auto;
    padding: 10px 20px;
    background-color: $color-darker;
    border-radius: 10px;
    border: 1px outset $color-darker;
    margin: 10px auto 40px auto;
    & .input-hidden {
      position: absolute;
      left: -9999px;
    }

    & input[type="radio"]:checked + label > p {
      border: 0.1px outset $color-primary;
      box-shadow: 0 0 3px 3px $color-primary;
    }

    /* Stuff after this is only to make things more pretty */
    & input[type="radio"] + label > p {
      border: 1px outset #444;
      transition: 500ms all;
    }
    & label {
      & p {
        padding: 5px 10px;
        border: 0.1px inset $color-darker;
        border-radius: 10px;
        font-size: 1em;
        color: $color-primary;
      }
    }
  }
  .newcat {
    display: flex;
    flex-direction: column;
    align-items: center;
    & p {
      font-size: 0.7em;
    }
    & input {
      font-size: 1em;
      padding: 5px 10px;
      border: 0.1px $color-primary outset;
      border-radius: 30px;
      background-color: $color-text-light;
      color: $color-primary;
    }
    & button {
      margin-top: 10%;
      border: 0.5px $color-primary outset;
      border-radius: 1.5rem;
      padding: 4px 8px;
      background: $color-darker;
      color: $color-text-light;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        border: 0.5px $color-primary inset;
        background-color: $color-primary;
        color: $color-text;
        transform: scale(97%);
      }
    }
  }
}
</style>
