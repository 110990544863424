import emailjs from "emailjs-com";

const state = {
  sendingEmail: false,
  emailSent: false,
  emailError: null,
};

const getters = {
  StateSendingEmail: (state) => state.sendingEmail,
  StateEmailSent: (state) => state.emailSent,
  StateEmailError: (state) => state.emailError,
};

const mutations = {
  setSendingEmail(state, value) {
    state.sendingEmail = value;
  },
  setEmailSent(state, value) {
    state.emailSent = value;
  },
  setEmailError(state, error) {
    state.emailError = error;
  },
};

const actions = {
  SendContactEmail({ commit }, data) {
    const userId = "x5ngu2KY4QLEeuiWa";
    const serviceId = "service_vgjdmf8";
    const templateId = "template_y2yk6v7";

    commit("setSendingEmail", true);
    commit("setEmailSent", false);
    commit("setEmailError", null);

    emailjs
      .sendForm(serviceId, templateId, data, userId)
      .then(() => {
        commit("setSendingEmail", false);
        commit("setEmailSent", true);
        commit("setEmailError", null);
      })
      .catch((error) => {
        commit("setSendingEmail", false);
        commit("setEmailSent", false);
        commit("setEmailError", error);
      });
  },

  SendBookingEmail({ commit }, data) {
    const userId = "x5ngu2KY4QLEeuiWa";
    const serviceId = "service_vgjdmf8";
    const templateId = "template_l17270u";

    commit("setSendingEmail", true);
    commit("setEmailSent", false);
    commit("setEmailError", null);

    emailjs
      .sendForm(serviceId, templateId, data, userId)
      .then(() => {
        commit("setSendingEmail", false);
        commit("setEmailSent", true);
        commit("setEmailError", null);
      })
      .catch((error) => {
        commit("setSendingEmail", false);
        commit("setEmailSent", false);
        commit("setEmailError", error);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
