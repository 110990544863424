<template>
  <div class="flip flip-vertical">
    <button class="button">
      <span class="button-text"
        ><a href="tel:+33669709177"
          ><i class="fas fa-phone"></i> Appelez-nous</a
        ></span
      >
      <div class="fill-container"></div>
    </button>
  </div>
</template>

<script>
export default {
  name: "FlipPhone",
};
</script>

<style lang="scss" scoped>
.button {
  position: relative;
  padding: 5px 20px;
  border-radius: 100vw;
  background-color: $color-primary;
  color: $color-text-light;
  border: solid 1px rgba($color: #fafafa, $alpha: 0.3);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); // force safari to respect border radius

  .button-text {
    position: relative;
    z-index: 2;
    & a {
      color: $color-text-light;
      font-size: 1em;
    }
  }

  .fill-container {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding-bottom: 100%;
    transform: translateY(-50%) rotate(180deg);

    // collor filling
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $color-darker;
      border-radius: 50%;
      transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
      transform: translateY(-100%);
    }
  }

  &:hover {
    border-color: $color-secondary;
    transform: translateY(-4px);

    .fill-container {
      transform: translateY(-50%) rotate(0);

      &::after {
        transform: translateY(0);
      }
    }
  }
}
</style>
