<template>
  <div class="container-fluid title-admin">
    <h1 class="text-center">Bienvenue dans votre zone Admin</h1>
  </div>
  <div class="container-fluid btn-row">
    <button type="submit" class="dashboard_btn" @click="getUsers">Users</button>
    <button type="submit" class="dashboard_btn" @click="getCats">
      Categories
    </button>
    <button type="submit" class="dashboard_btn" @click="getTags">Tags</button>
    <button type="submit" class="dashboard_btn" @click="getMedias">
      Images article
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DashBoard",
  computed: {
    ...mapGetters({
      Users: "StateUsers",
      Posts: "StatePosts",
      Categories: "StateAllCategory",
      Tags: "StateAllTags",
      Comments: "StateComments",
      Medias: "StateAllPostMedia",
    }),
  },
  methods: {
    ...mapActions([
      "GetPosts",
      "GetUsers",
      "GetAllCategory",
      "GetAllTag",
      "GetAllPostMedia",
      "GetComments",
    ]),
    async getUsers() {
      try {
        await this.GetUsers();
        this.$router.push({
          name: "mala-users",
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async getCats() {
      try {
        await this.GetAllCategory();
        this.$router.push({
          name: "mala-cats",
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async getTags() {
      try {
        await this.GetAllTag();
        this.$router.push({
          name: "mala-tags",
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async getMedias() {
      try {
        await this.GetAllPostMedia();
        this.$router.push({
          name: "mala-medias",
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-admin {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}
.btn-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 100px 0px;
}

.dashboard_btn {
  position: relative;
  width: 500px;
  margin: 10px 10px;
  padding: 5px 20px;
  border-radius: 100vw;
  background-color: $color-primary;
  color: $color-text-light;
  border: solid 1px rgba($color: #fafafa, $alpha: 0.3);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
  -webkit-mask-image: -webkit-radial-gradient(
    white,
    black
  ); // force safari to respect border radius

  .button-text {
    position: relative;
    z-index: 2;
    & a {
      color: $color-text-light;
      font-size: 0.8em;
    }
  }

  .fill-container {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding-bottom: 100%;
    transform: translateY(-50%) rotate(180deg);

    // collor filling
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $color-darker;
      border-radius: 50%;
      transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); // easeOutQuart
      transform: translateY(-100%);
    }
  }

  &:hover {
    border-color: $color-secondary;
    transform: translateY(-4px);

    .fill-container {
      transform: translateY(-50%) rotate(0);

      &::after {
        transform: translateY(0);
      }
    }
  }
}
</style>
