import axios from "axios";

const state = {
  posts: null,
  post: null,
  hydraview: null,
};

const getters = {
  StatePosts: (state) => state.posts,
  StatePost: (state) => state.post,
  StateHydraview: (state) => state.hydraview,
};

const actions = {
  async GetPosts({ commit }, page = null) {
    const url = page ? page : "articles?order[publishedAt]=desc";
    let response = await axios.get(url);
    commit("setPosts", response.data["hydra:member"]);
    commit("setHydraview", response.data["hydra:view"]);
  },
  async GetPostsPublished({ commit }, page = null) {
    const url = page
      ? page
      : "articles?order[publishedAt]=desc&isPublished=true";
    let response = await axios.get(url);
    commit("setPosts", response.data["hydra:member"]);
    commit("setHydraview", response.data["hydra:view"]);
  },

  async GetPost({ commit }, articleId) {
    let response = await axios.get("articles/" + articleId);
    commit("setPost", response.data);
  },
  async GetPostBySlug({ commit }, slug) {
    let response = await axios.get("articles" + "?&slug=" + slug);
    let articles = response.data["hydra:member"];
    let article = articles[0]; // Sélectionnez le premier article du tableau
    response = await axios.get("articles/" + article.id);
    commit("setPost", response.data);
  },

  async CreatePost({ dispatch }, post) {
    await axios.post("articles", post);
    return await dispatch("GetPosts");
  },

  async UpdatePost({ dispatch }, data) {
    await axios.put("articles/" + data.id, data);
    return await dispatch("GetPosts");
  },

  async DeletePost({ dispatch }, articleId) {
    await axios.delete("articles/" + articleId);
    return await dispatch("GetPosts");
  },
};

const mutations = {
  setPosts(state, posts) {
    state.posts = posts;
  },
  setPost(state, post) {
    state.post = post;
  },
  setHydraview(state, hydraview) {
    state.hydraview = {};

    if (hydraview["hydra:first"]) {
      state.hydraview["hydra:first"] = hydraview["hydra:first"].replace(
        "/api/",
        ""
      );
    }

    if (hydraview["hydra:last"]) {
      state.hydraview["hydra:last"] = hydraview["hydra:last"].replace(
        "/api/",
        ""
      );
    }

    if (hydraview["hydra:next"]) {
      state.hydraview["hydra:next"] = hydraview["hydra:next"].replace(
        "/api/",
        ""
      );
    }

    if (hydraview["hydra:previous"]) {
      state.hydraview["hydra:previous"] = hydraview["hydra:previous"].replace(
        "/api/",
        ""
      );
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
