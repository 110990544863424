<template>
  <div class="container-fluid mt-5 pt-5">
    <p class="header-plugin">Sélectionnez l'image à supprimé :</p>
  </div>
  <div class="container-fluid plugin-media">
    <div class="left col-12 col-lg-4">
      <div class="mediaList">
        <div v-for="image in AllPostMedia" :key="image.id" class="px-1">
          <input
            v-model="checkedImage"
            @input="sharePostMedia"
            type="radio"
            :value="image"
            name="radioImage"
            :id="image.contentUrl"
            class="input-hidden"
          />
          <label :for="image.contentUrl">
            <img
              :src="'http://127.0.0.1:8000' + image.contentUrl"
              alt="article-image"
            />
          </label>
        </div>
      </div>
      <div class="text-center newfile">
        <button
          type="submit"
          @click="deleteImg(checkedImage)"
          class="btn-primary"
        >
          supprimer
        </button>
      </div>
    </div>
    <div class="right col-12 col-lg-6">
      <span v-if="checkedImage != null && checkedImage != ''">
        <div class="showImage">
          <img
            :src="'http://127.0.0.1:8000' + checkedImage.contentUrl"
            alt="article-image"
            width="250"
            height="250"
          />
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MalaMedias",
  components: {},
  created: function () {
    // a function to call getposts action
    this.GetAllPostMedia();
  },
  data() {
    return {
      checkedImage: "",
    };
  },
  computed: {
    ...mapGetters({
      Profile: "StateProfile",
      User: "StateUser",
      AllPostMedia: "StateAllPostMedia",
      PostMedia: "StatePostMedia",
    }),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },

  watch: {
    checkedImage(newValue) {
      this.$emit("img-choice", newValue);
    },
  },
  methods: {
    ...mapActions(["DeletePostMedia", "GetAllPostMedia", "setPostMedia"]),
    async deleteImg(imgId) {
      const mediaId = imgId["@id"].replace("/api/", "");
      try {
        await this.DeletePostMedia(mediaId);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async submitPostMedia() {
      try {
        this.file = this.$refs.file.files[0];
        let formData = new FormData();
        formData.append("file", this.file);
        await this.CreatePostMedia(formData);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async displayRadioValue() {
      let ele = document.getElementsByName("radioImage");
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].checked) {
          this.checkedImage = ele[i];
        }
      }
    },
    sharePostMedia() {
      this.$emit("img-choice", this.checkedImage);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-plugin {
  text-align: start;
  margin: 20px 20px 40px 20px;
}
.plugin-media {
  margin-bottom: 20px;
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-around;
  }
  & .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    & .newfile {
      display: flex;
      flex-direction: column;
      align-items: center;
      & p {
        font-size: 0.7em;
      }
      & input {
        font-size: 0.8em;
      }
      & button {
        margin-top: 10%;
        margin-bottom: 10%;
        border: 0.5px $color-primary outset;
        border-radius: 1.5rem;
        padding: 4px 8px;
        background: $color-darker;
        color: $color-text-light;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          border: 0.5px $color-primary inset;
          background-color: $color-primary;
          color: $color-text;
          transform: scale(97%);
        }
      }
    }
  }
  & .showImage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    & img {
      width: 100%;
    }
  }
}
.mediaList {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 70%;
  overflow: auto;
  padding: 10px 20px;
  background-color: $color-darker;
  border-radius: 10px;
  border: 1px outset $color-darker;
  margin-bottom: 40px;
}
.postImg {
  border: 1px outset $color-darker;
}
.postImgSelected {
  border: 1px inset green;
}
.input-hidden {
  position: absolute;
  left: -9999px;
}

input[type="radio"]:checked + label > img {
  border: 0.1px solid $color-primary;
  box-shadow: 0 0 3px 3px $color-primary;
}

/* Stuff after this is only to make things more pretty */
input[type="radio"] + label > img {
  border: 0.1px outset #444;
  width: 100px;
  height: 100px;
  transition: 500ms all;
}
</style>
