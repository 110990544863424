<template>
  <div class="devis">
    <DevisHeader />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import DevisHeader from "@/components/devis/DevisHeader.vue";

export default {
  components: {
    DevisHeader,
  },
  setup() {
    useMeta({
      title: "DEVIS: Cool Roof",
      description: [
        {
          content:
            "Obtenez un devis gratuit pour votre projet de Cool Roof. Maximisez l'efficacité énergétique de votre toiture tout en réduisant les coûts énergétiques.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr/devis",
        },
      ],
    });
  },
};
</script>
