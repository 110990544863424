<template>
  <div class="p-lg-1 mt-lg-5 pt-5">
    <!--Section: Content-->
    <section class="container-fluid container-lg dark-grey-text mb-5 contact">
      <!-- Section heading -->
      <div class="header">
        <h2 class="text-center mt-2">Le Cool-Roof en France</h2>
        <h3 class="fall text-center mb-4">
          <span>avec</span><br />
          NUBETEC
        </h3>
        <p class="text-center w-responsive mx-auto">
          Nubetec est votre partenaire de confiance pour toutes vos solutions
          <strong>Cool Roof</strong> en France. Notre entreprise intervient dans
          dans tout le pays pour vous offrir des
          <strong>toitures écologiques et écoénergétiques</strong>.
        </p>
        <p class="text-center w-responsive mx-auto pb-5">
          Grâce au procédé <strong>Cool Roof</strong>, Nubetec améliore
          <strong>l'efficacité énergétique</strong>
          de vos bâtiments en réduisant les
          <strong>îlots de chaleur urbains</strong> et en minimisant votre
          empreinte carbone.
        </p>
      </div>

      <!--Section: Content-->
      <section class="container-fluid right-block mt-5 mb-5">
        <!-- Grid row -->

        <!-- Grid column -->
        <div class="form mb-lg-0 p-2 slide-left">
          <!-- Form with header -->
          <div class="form-header blue accent-1">
            <h3 class="mt-2"><i class="fas fa-envelope"></i> Une question ?</h3>
          </div>
          <div class="card">
            <div class="card-body">
              <!-- Header -->
              <p class="dark-grey-text">
                N'attendez plus, économisez sur votre facture énergétique grâce
                au <strong>cool-roof</strong>.
              </p>
              <!-- Body -->
              <form ref="formData" @submit.prevent="sendEmail">
                <div class="md-form mt-3">
                  <i class="fas fa-user prefix grey-text ml-5"></i>
                  <label class="mx-2" for="name"> Nom</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    class="form-control"
                    maxlength="30"
                    required
                  />
                </div>
                <div class="md-form mt-3">
                  <i class="fas fa-envelope prefix grey-text"></i>
                  <label for="email" class="mx-2">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    class="form-control"
                  />
                </div>
                <div class="md-form mt-3">
                  <i class="fas fa-tag prefix grey-text"></i>
                  <label for="subject" class="mx-2">Titre</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    maxlength="50"
                    required
                    pattern="^[^/<>\n]*$"
                    class="form-control"
                  />
                </div>
                <div class="md-form my-3">
                  <i class="fas fa-pencil-alt prefix grey-text"></i>
                  <label for="message" class="mx-2">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    pattern="^[^/<>\n]*$"
                    maxlength="450"
                    required
                    class="form-control md-textarea"
                    rows="3"
                  ></textarea>
                </div>
                <div class="text-center">
                  <span v-if="this.isCaptchaVisible">
                    <vue-hcaptcha
                      sitekey="dd93e40f-34be-411f-b55c-68375ab95668"
                      @verify="onVerify"
                      @error="onError"
                    ></vue-hcaptcha>
                  </span>

                  <button class="btn btn-light-blue mt-4">Envoyer</button>
                </div>
              </form>
              <span v-if="this.modalConfirm">
                <ConfirmModal
                  @closeModalConfirm="closeModalConfirm"
                  title="Merci de votre intérêt"
                  message="Vous allez être recontacté dans les plus brefs délais"
                  btnExit="À bientôt"
                />
              </span>
            </div>
          </div>
          <!-- Form with header -->
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-lg-7">
          <div class="container-fluid arguments">
            <div style="width: 100%">
              <iframe
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=metz+(Nubetec)&amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="100%"
                height="450"
                frameborder="0"
              ></iframe>
            </div>
            <div class="background-contact fall">
              <div class="contact-bar">
                <div>
                  <a href="#" class="btn-floating blue accent-1">
                    <i class="fas fa-map-marker-alt"></i>
                  </a>
                  <p>
                    23 rue de Sarre<br />
                    Metz, 57070
                  </p>
                </div>
                <div class="">
                  <a href="tel:+33669709177" class="btn-floating blue accent-1">
                    <i class="fas fa-phone"></i>
                  </a>
                  <p>
                    06 69 70 91 77<br />
                    Lun - Sam, 8:30-19:00
                  </p>
                </div>
                <div class="">
                  <a
                    href="mailto:contact@nubetecfr"
                    class="btn-floating blue accent-1"
                  >
                    <i class="fas fa-envelope"></i>
                  </a>
                  <p>
                    contact@nubetec.fr<br />
                    chanez.vehert@nubetec.fr
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Buttons-->
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </section>
    <div class="container-fluid mt-5 fall">
      <div class="row text-center">
        <h3 class="font-weight-bold text-center mt-2 mb-4">
          Économisez sur votre facture énergétique
        </h3>
        <!-- Section description -->
        <p class="text-center w-responsive mx-auto px-lg-5 pb-5">
          Que vous soyez un particulier ou une entreprise, notre équipe
          qualifiée est là pour vous accompagner dans la mise en place d'un
          système de <strong>Cool Roof</strong> performant. Grâce à notre
          expertise technique et notre engagement environnemental, nous vous
          aidons à <strong>réduire votre consommation d'énergie</strong>, à
          améliorer l'<strong>efficacité thermique</strong> de votre bâtiment et
          à contribuer à la préservation de notre planète. Faites confiance à
          Nubetec pour des solutions de toiture novatrices qui allient
          performance, esthétisme et durabilité. Contactez-nous dès aujourd'hui
          pour en savoir plus sur nos services et bénéficiez d'une toiture qui
          fait la différence.
        </p>
      </div>
    </div>
    <!--Section: Content-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  name: "contactForm",
  components: { VueHcaptcha, ConfirmModal },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left");
    });
  },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      modalConfirm: false,
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendContactEmail"]),
    sendEmail() {
      if (this.isCaptchaVerified) {
        this.SendContactEmail(this.$refs.formData);
        this.modalConfirm = true;
        this.isCaptchaVerified = false;
        this.isCaptchaVisible = false;
        this.$refs.formData.reset();
      } else {
        this.isCaptchaVisible = true;
      }
    },
    onVerify(token, eKey) {
      if (!token && !eKey) {
        alert("Un probléme est survenue lors de la validation du captcha");
      } else {
        this.isCaptchaVerified = true;
      }
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & span {
    font-size: 0.8em;
  }
  & h2 {
    margin-bottom: 0px;
    font-size: 3em;
    background-color: $background;
  }
  h2 {
    display: inline;
    position: initial;
    padding: 0px; /* Espacement interne pour le contenu du titre */
    color: $color-primary; /* Couleur du texte */
    z-index: 1;
  }

  h2:before {
    content: "";
    position: initial;
    top: 0;
    right: 0; /* Ajustez cette valeur pour contrôler l'angle de la diagonale */
    bottom: 0;
    width: 100%;
    background-color: black; /* Couleur de fond de la bannière */
    transform: skewX(
      0deg
    ); /* Ajustez cet angle pour contrôler l'inclinaison de la diagonale */
    z-index: -1;
  }
  & h3 {
    margin-top: -50px;
    font-weight: 100;
    font-size: 2.3em;
  }
}
.arguments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  @media screen and (min-width: 992px) {
    //flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    width: 100%;
    min-height: 100%;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-darker;
    @media screen and (min-width: 480px) {
      flex-direction: row;
      justify-content: space-between;
      border-radius: 0px 25px 0px 0px;
    }
    & .side-p {
      background-color: $color-darker;
      border-radius: 8px;
      & h3 {
        color: $color-text-light;
        text-align: right;
        margin: 10px 8px;
      }
      & p {
        background-color: $color-primary;
        padding: 15px;
        margin: 0px;
        border-radius: 25px 0px 0px 0px;
        text-align: right;
      }
    }
    & img {
      display: none;
      @media screen and (min-width: 480px) {
        display: block;
        width: 150px;
      }
    }
  }
  & .reverse {
    border-radius: 0px;
    & h3 {
      text-align: left !important;
    }
    & p {
      border-radius: 0px 25px 0px 0px !important;
      text-align: left !important;
    }
  }
}
.map-container-section {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.map-container-section iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.form {
  color: $color-text;
  background-color: $color-darker;
  position: relative;
  & h3 {
    color: $color-text-light;
  }
  & .card-body {
    background-color: $color-primary;
  }
}
.right-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-darker;
    padding-right: 0px;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      border-radius: 25px 0px 0px 25px;
    }
    & .side-p {
      background-color: $color-darker;
      border-radius: 8px;
      & h3 {
        color: $color-text-light;
      }
      & p {
        background-color: $color-primary;
        padding: 15px;
        margin: 0px;
      }
    }
    & img {
      max-width: 200px;
    }
  }
  .background-contact {
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    & .contact-bar {
      background-color: $color-darker;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px $color-text-light outset;
      border-radius: 0px 0px 7px 7px;
      text-align: center;
      padding: 8px 20px;
      margin-top: 20px;
      @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-around;
      }
      & p {
        color: $color-text-light;
        text-align: center;
        line-height: 1.5rem;
        font-size: 1em;
      }
      & i {
        font-size: 2em;
        color: $color-primary;
        margin-bottom: 10px;
      }
    }
  }
}
.btn {
  border: 0.2px $color-text-light outset;
  &:hover {
    border-style: inset;
    scale: 99%;
  }
}
.fall.active {
  @include fall-down(1s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
</style>
