<template>
  <div class="container my-5">
    <!--Section: Content-->
    <section class="dark-grey-text">
      <!-- Section heading -->

      <!-- Grid row -->
      <div class="container mb-5">
        <h2 class="text-left font-weight-bold mb-4">
          Le Cool-roofing : qu'est-ce que c'est ?
        </h2>
        <p class="text-left mx-auto mb-5">
          Le <strong>cool-roof</strong> est un procédé conçu pour réduire la
          chaleur absorbée par un bâtiment. Il consiste à appliquer une peinture
          réfléchissante permettant de renvoyer une plus grande quantité de
          rayonnements solaires, et qui a pour effet de
          <strong>réduire la chaleur</strong> transférée à l'intérieur de la
          structure. En minimisant l'absorption de chaleur, le
          <strong>cool-roof</strong> contribue à améliorer l'<strong
            >efficacité énergétique</strong
          >
          du bâtiment, à <strong>réduire les coûts de climatisation</strong> et
          à atténuer l'effet des <strong>îlots de chaleur urbains</strong>.
        </p>
      </div>
      <div class="block-row pt-5">
        <!-- Grid column -->
        <div class="block-text col-lg-5 slide-left">
          <!-- Featured image -->

          <!-- Section description -->
          <h3 class="mb-3">Les effets du cool-roof</h3>
          <p class="text-left mx-auto w-responsive">
            Le <strong>cool-roof</strong> "<strong>toiture fraiche</strong>" est
            un procédé qui consiste à appliquer une
            <strong>peinture réflective</strong> de couleur blanche.
            <br />
            La peinture va
            <strong>réfléchir 95% du rayonnement solaire</strong>. Cette
            technique a fait ses preuves. Elle est utilisée depuis toujours,
            notamment dans les pays chauds.
            <br />
            Aujourd’hui les grandes villes et les zones commerciales optent pour
            le <strong>cool-roof</strong> dans le but de réduire les
            <strong>îlots de chaleurs urbains</strong> ainsi que la
            <strong>consommation d’énergie</strong>.
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-12 col-lg-6 img-double slide-right">
          <img
            src="@/assets/pictures/with-cool-roof.webp"
            alt="graphic-economy-energy"
            class="img-fluid"
          />
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>

  <!--Section: Content-->
  <section class="container mt-5">
    <!-- Grid row -->
    <div class="block-row reverse">
      <!-- Grid column -->
      <div class="block-text slide-right col-lg-5">
        <!-- Category -->
        <!-- Post title -->
        <h3 class="mb-3">Pourquoi renvoyer le rayonnement solaire ?</h3>
        <!-- Excerpt -->
        <p>
          En été la température de votre toit peut atteindre 85°C en surface.<br />
          Cette quantité de chaleur va donc créer une
          <strong>surchauffe du bâtiment</strong>.<br />
          Le procédé <strong>Cool-Roof</strong> permet une forte
          <strong>réduction de la température de votre toit</strong>, passant de
          85°C à 40°C.<br />
          Avec pour résultat une
          <strong>baisse de la température</strong> jusqu'à 6°C à l'intérieur du
          bâtiment.
        </p>
      </div>
      <!-- Grid column -->
      <!-- Grid column -->
      <div class="col-lg-6">
        <!-- Featured image -->
        <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
          <img
            src="@/assets/pictures/without-cool-roof.webp"
            alt="graphic-economy-energy"
            class="img-fluid"
          />
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
  </section>
  <!--Section: Content-->
</template>

<script>
export default {
  name: "ServicesFirst",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left");
    });
  },
};
</script>

<style lang="scss" scoped>
.block-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.hr {
  width: 50px;
  height: 3px;
  background-color: $background;
}

.reverse {
  flex-direction: column-reverse;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse !important;
  }
}
video,
img {
  width: 500px;
  max-width: 90vw;
  margin-bottom: 40px;
  @media screen and (min-width: 992px) {
    width: 500px;
    margin-bottom: 0px;
    margin-top: 30px;
  }
}
.img-double {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  position: absolute;
  top: 13%;
  width: 100vw;
  height: 70vh;
  padding: 10px 20px;
}
.fall.active {
  @include fall-down(1.2s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1s);
}
</style>
