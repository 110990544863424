<template>
  <div class="container social-link z-depth-1">
    <!--Section: Content-->
    <section class="px-md-5 mx-md-5 text-center dark-grey-text">
      <h4 class="text-center">Retrouvez-nous:</h4>

      <!--Facebook-->
      <div class="link">
        <a href="https://www.facebook.com/nubetecfrance" class="mx-1"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a href="https://twitter.com/NubeTecFrance" class="mx-1" role="button"
          ><i class="fab fa-twitter"></i
        ></a>
        <a href="https://www.linkedin.com/company/nubetec" class="mx-1"
          ><i class="fab fa-linkedin-in"></i
        ></a>
        <a href="https://www.instagram.com/nubetec.france/" class="mx-1"
          ><i class="fab fa-instagram"></i
        ></a>
        <a href="https://www.pinterest.fr/nubetec/" class="mx-1" role="button"
          ><i class="fab fa-pinterest"></i
        ></a>
        <a href="https://www.youtube.com/@Nubetecfrance" class="mx-1"
          ><i class="fab fa-youtube"></i
        ></a>
      </div>
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "contactCard",
};
</script>

<style lang="scss" scoped>
.social-link {
  margin-bottom: 50px;
  & h4 {
    margin: 40px 0px;
  }
  & .link {
    display: flex;
    justify-content: center;
    i {
      color: $color-primary;
      font-size: 1.8em;
      margin: 3px 10px;
    }
  }
}
</style>
