<template>
  <!--Section: Content-->
  <section class="container-fluid onFooter">
    <div class="onFooter__item call-action__img">
      <img
        src="@/assets/brand/white-sigle.svg"
        alt="logo"
        width="150"
        height="auto"
        class="img-fluid mx-5"
      />
    </div>
    <!--Grid row-->
  </section>
  <!--Section: Content-->
</template>

<script>
export default {
  name: "OnFooter",
};
</script>

<style lang="scss" scoped>
.onFooter {
  background-color: $color-darker;
  padding: 20px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
}
</style>
