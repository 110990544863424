<template>
  <div class="container">
    <h2 id="ilot" class="text-left font-weight-bold mb-4 pb-2">
      Le confort thermique.
    </h2>
    <!-- Section description -->
    <p class="text-left mx-auto w-responsive">
      <strong>Diminuer la température</strong> à l'intérieur des bâtiments
      améliore la sensation de bien-être.
    </p>
    <p>
      Que ce soit pour vos collaborateurs, vos clients ou votre matériel, un
      <strong>bâtiment frais</strong> sera un atout dans votre productivité.
    </p>
    <p class="text-left mx-auto w-responsive mb-5">
      Nubetec vous accompagne dans votre démarche de
      <strong>rafraîchissement passif</strong> avec l'application d'une
      <strong>peinture réflective</strong> et ainsi diminuer la température
      intérieure jusqu'à 6°C.
    </p>
  </div>
  <div class="container">
    <section class="row-icons">
      <div class="row-icons__group fall1">
        <img
          src="@/assets/pictures/aliments.webp"
          alt="environnement-work-market"
          class="img-fluid"
        />
      </div>
      <div class="row-icons__group fall2">
        <img
          src="@/assets/pictures/bureau.webp"
          alt="environnement-work-office"
          class="img-fluid"
        />
      </div>
      <div class="row-icons__group fall3">
        <img
          src="@/assets/pictures/garage.webp"
          alt="environnement-work-garage"
          class="img-fluid"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServicesThird",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall1", ".fall2", ".fall3");
    });
  },
};
</script>

<style lang="scss" scoped>
.row-icons {
  display: flex;
  justify-content: space-between;
  &__group {
    text-align: center;
    & img {
      width: 90%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    }
    & p {
      font-size: 0.7em;
    }
  }
}
.fall1.active {
  @include pop(1s);
}
.fall2.active {
  @include pop(3s);
}
.fall3.active {
  @include pop(4s);
}
</style>
