<template>
  <header
    :style="{
      backgroundImage:
        'url(https://api-blog.nubetec.fr' + post.img.contentUrl + ')',
    }"
  >
    <div class="item">
      <div
        class="wrapper container d-flex justify-content-around align-items-start"
      >
        <div class="speech">
          <h1 class="title fall">{{ post.title }}</h1>
        </div>
      </div>
    </div>
  </header>
  <section class="container mt-5 pt-lg-5 post">
    <div v-html="post.content"></div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
import Prism from "prismjs";
import { useMeta } from "vue-meta";
import { toRef } from "vue";
export default {
  name: "PostRead",
  setup(props) {
    const post = toRef(props, "post");
    console.log(post.value.slug);
    const processContentForMetaDescription = (content, maxLength = 160) => {
      const contentWithoutStyles = content.replace(
        /<style([\s\S]*?)<\/style>/gi,
        ""
      );
      const contentWithoutTags = contentWithoutStyles.replace(/<[^>]+>/g, "");

      let metaDescription = contentWithoutTags.slice(0, maxLength);
      if (contentWithoutTags.length > maxLength) {
        metaDescription += "...";
      }

      return metaDescription;
    };
    const metaDescription = processContentForMetaDescription(
      post.value.content
    );

    useMeta({
      title: post.value.title,
      description: [
        {
          content: metaDescription,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr/" + post.value.slug,
        },
      ],
    });
  },
  /*setup() {
    const post = "new titre";
    console.log(post);
    useMeta({
      title: post,
      description: [
        {
          content:
            "Nubetec: Pionnier du Cool Roof pour toitures éco-responsables. Réduisez la chaleur, économisez l'énergie et contribuez à un avenir durable. Contactez-nous dès maintenant !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr",
        },
      ],
    });
  },*/
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backgroundImage: this.post.img.contentUrl,
    };
  },
  computed: {
    hasBackgroundImage() {
      return this.backgroundImage !== undefined;
    },
    formattedDate() {
      return formatDate(this.post.updatedAt);
    },
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
    }),
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left");
    });
    // Sélectionne toutes les balises img contenues dans la balise div avec la classe "post"
    const postImages = document.querySelectorAll(".post img");
    // Parcourt chaque balise img dans la classe "post"
    postImages.forEach((img) => {
      // Supprime toutes les balises style de l'image
      img.removeAttribute("style");

      // Ajoute la classe img-fluid et img-post à l'image
      img.classList.add("img-fluid", "img-post", "col-10");

      // Crée une nouvelle div parent
      const wrapper = document.createElement("div");
      wrapper.classList.add("container", "text-center", "my-5");

      // Insère la nouvelle div avant l'image existante
      img.parentNode.insertBefore(wrapper, img);

      // Déplace l'image à l'intérieur de la nouvelle div
      wrapper.appendChild(img);
      // apply Prism highlighting to the rendered content
      Prism.highlightAll();
    });
  },
};
</script>

<style lang="scss" scoped>
header {
  /*@include background-responsive;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @media screen and (min-width: 992px) {
    background-position: center center;
  }
  & .item {
    width: 100%;
    height: fit-content;
    min-height: 60vh;
    padding: 75px 0;
    background: #0000009f;
    @media screen and (min-width: 992px) {
      min-height: 90vh;
    }
  }
}

.wrapper {
  position: absolute;
  top: 7%;
  width: 100%;
  height: 80vh;
  padding: 10px 20px;
  & .speech {
    margin: auto;
    & .title {
      margin-top: 20px;
      text-align: left;
      color: $color-text-light;
      font-weight: 200;
      font-size: 2em;
      @media screen and (min-width: 992px) {
        font-size: 4em;
        margin-top: 5px;
        margin-left: 20px;
      }
    }
  }
}
.fall.active {
  @include fall-down(1.2s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1s);
}
.cadre-img {
  display: flex !important;
  justify-content: center !important;
}
h2 {
  display: inline-block;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px !important;
  padding: 10px 8px 10px 5px; /* Espacement interne pour le contenu du titre */
  background-color: $color-primary; /* Couleur de fond de la bannière */
  color: $color-text-light; /* Couleur du texte */
  z-index: 1;
  @media screen and (min-width: 992px) {
    padding: 10px 100px 10px 8px;
  }
  @media screen and (max-width: 330px) {
    padding: 5px 10px 5px 5px;
  }
}

h2:before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px; /* Ajustez cette valeur pour contrôler l'angle de la diagonale */
  bottom: 0;
  width: 50%;
  background-color: $color-primary; /* Couleur de fond de la bannière */
  transform: skewX(
    -16deg
  ); /* Ajustez cet angle pour contrôler l'inclinaison de la diagonale */
  z-index: -1;
}
</style>
