<template>
  <div class="modal-confirm">
    <div class="container">
      <div class="row">
        <p class="title">Changer votre mot de passe</p>
      </div>
      <div class="text-center">
        <form>
          <input
            type="password"
            name="password"
            placeholder="Mot de passe actuel"
            v-model="form.password"
          />
          <input
            type="password"
            name="newPassword"
            placeholder="Nouveau mot de passe"
            v-model="form.newPassword"
          />
          <input
            type="password"
            name="verifpwd"
            placeholder="Confirmer le nouveau mot de passe"
            v-model="verifpwd"
          />
        </form>
      </div>
      <div class="row text-end">
        <button @click="updatePassword">Changer</button>
        <button @click="closeModalUpdatePassword">Quitter</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ModalUpdatePassword",
  props: {
    idUser: Number,
  },
  data() {
    return {
      form: {
        password: "",
        newPassword: "",
        id: this.idUser,
      },
      verifpwd: "",
      showError: false,
    };
  },
  methods: {
    ...mapActions(["UpDatePassword"]),
    async updatePassword() {
      try {
        await this.UpDatePassword(this.form);
      } catch (error) {
        this.showError = true;
      }
      this.$emit("updatePassword");
    },
    closeModalUpdatePassword() {
      this.$emit("closeModalUpdatePassword");
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  & input {
    padding: 5px 10px;
    margin-bottom: 10px;
  }
}
.modal-confirm {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  //background-color: $color-darker;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 40px 20px 20px;
  border: 0.5px outset $color-primary;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  & .title {
    text-align: center;
    color: $color-primary;
    font-size: 1.2em;
    font-weight: 800;
    margin-bottom: 10px;
  }
  & p {
    color: $color-secondary;
    font-weight: 550;
    margin-bottom: 30px;
  }
  & button {
    color: $color-secondary;
    background-color: $color-primary;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 10px;
    margin: 20px auto;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      color: $color-primary;
      background-color: $color-secondary;
    }
  }
}
</style>
