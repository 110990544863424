import axios from "axios";

const state = {
  users: null,
  comments: null,
};

const getters = {
  StateUsers: (state) => state.users,
  StateComments: (state) => state.comments,
};

const actions = {
  async GetUsers({ commit }) {
    let response = await axios.get("users");
    commit("setUsers", response.data["hydra:member"]);
  },

  async DeleteUser({ dispatch }, userId) {
    await axios.delete("users/" + userId);
    return await dispatch.state.users;
  },

  async GetComments({ commit }) {
    let response = await axios.get("comments");
    commit("setComments", response.data);
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },

  setComments(state, comments) {
    state.comments = comments;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
