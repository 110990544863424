<template>
  <div class="container">
    <section class="row-icons">
      <div class="col-sm-6 col-md-4 col-xl-2 row-icons__group">
        <img src="@/assets/icons/paint.svg" alt="cool-roof-icon" />
        <p>Le <strong>cool-roof</strong>ing</p>
      </div>
      <div class="col-sm-6 col-md-4 col-xl-2 row-icons__group">
        <img src="@/assets/icons/trade.svg" alt="icon-graphic-down-energie" />
        <p><strong>Économies énergie</strong></p>
      </div>
      <div class="col-sm-6 col-md-4 col-xl-2 row-icons__group">
        <img src="@/assets/icons/thermo.svg" alt="icon-thermometer" />
        <p><strong>Confort thermique</strong></p>
      </div>
      <div class="col-sm-6 col-md-4 col-xl-2 row-icons__group">
        <img src="@/assets/icons/leef.svg" alt="icon-leaf-environement" />
        <p><strong>Écologie</strong></p>
      </div>
      <div class="col-sm-6 col-md-4 col-xl-2 row-icons__group">
        <img src="@/assets/icons/reflect.svg" alt="painting-protocted" />
        <p><strong>Îlot de chaleur</strong></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "RowIcons",
};
</script>

<style lang="scss" scoped>
.row-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  padding: 5px 0px;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
  }
  &__group {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    @media screen and (min-width: 992px) {
    }
    & img {
      width: 50px;
      height: 50px;
      margin-bottom: 0px;
      @media screen and (min-width: 992px) {
        margin-bottom: 10px;
      }
    }
    & p {
      font-size: 1.2em;
      color: $color-text-light;
      margin-bottom: 0px;
    }
  }
}
.rightIcon {
  flex-direction: row-reverse;
}
</style>
