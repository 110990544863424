<template>
  <div class="container">
    <h2 id="eco" class="text-left font-weight-bold mb-4 pb-2">
      Économie d'énergie
    </h2>
    <!-- Section description -->
    <p class="text-left mx-auto w-responsive mb-5">
      En été, l’utilisation de la climatisation demande une consommation
      d’électricité conséquente.<br />NUBETEC, avec l’application de la
      <strong>peinture réflective</strong>, vous aide à
      <strong>économiser jusqu’à 40% sur vos factures d’électricité</strong>,
      tout en réduisant la température intérieure du bâtiment jusqu’à 6°C.<br />Le
      <strong>cool-roof</strong> est moins coûteux et moins contraignant que des
      travaux d’isolation traditionnels.<br />Durant le chantier, le système
      <strong>cool-roof</strong> génère peu de nuisances telles que le bruit,
      les déchets…
    </p>
  </div>
  <div class="container-fluid fall">
    <section class="row-icons">
      <div class="row-icons__group">
        <img src="@/assets/icons/valid.png" alt="economy-energy-graphic" />
        <p>Baisse jusqu’à -40% sur la facture d’électricité.</p>
      </div>
      <div class="row-icons__group">
        <img src="@/assets/icons/valid.png" alt="icon-shield-protect" />
        <p>Protège le revêtement et pérennise les toitures.</p>
      </div>
      <div class="row-icons__group">
        <img src="@/assets/icons/valid.png" alt="icon-mind-free" />
        <p>
          Sans contraintes durant les travaux (bruits, déchets de chantier…).
        </p>
      </div>
      <div class="row-icons__group">
        <img src="@/assets/icons/valid.png" alt="icon-less-euro" />
        <p>Moins coûteux que des travaux d’isolation classique.</p>
      </div>
      <div id="confort" class="row-icons__group">
        <img src="@/assets/icons/valid.png" alt="hand-handing-grow-plant" />
        <p>Diminution de son impact environnemental.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServicesSecond",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.row-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 100px;
  padding: 15px 0px;
  background: url("@/assets/pictures/poteaux.webp");
  @include background-responsive;
  border-radius: 10px;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
  }
  &__group {
    text-align: center;
    padding: 5px 10px;
    & img {
      width: 70px;
      margin-bottom: 20px;
    }
    & p {
      text-align: center;
      color: $color-primary;
    }
  }
}
.fall.active {
  @include fall-down(1.2s);
}
</style>
