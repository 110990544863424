<template>
  <header :style="{ backgroundImage: `url('${backgroundImage}')` }">
    <div class="item">
      <div class="wrapper d-flex justify-content-around align-items-start">
        <div class="speech">
          <p class="ants-title slide-left">{{ anteTitle }}</p>
          <p class="title fall">{{ title }}</p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderBase",
  props: {
    anteTitle: String,
    title: String,
    backgroundImage: String,
  },
  computed: {
    hasBackgroundImage() {
      return this.backgroundImage !== undefined;
    },
  },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left");
    });
  },
};
</script>

<style lang="scss" scoped>
header {
  /*@include background-responsive;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  @media screen and (min-width: 992px) {
    background-position: center center;
  }
  & .item {
    width: 100%;
    height: fit-content;
    min-height: 90vh;
    padding: 75px 0;
    background: #0000009f;
    @media screen and (min-width: 992px) {
      min-height: 90vh;
    }
  }
}

.wrapper {
  position: absolute;
  top: 13%;
  width: 100%;
  height: 80vh;
  padding: 10px 20px;
  & .speech {
    margin: auto;
    & .ants-title {
      position: relative;
      top: 42px;
      left: 0px;
      text-align: start;
      font-size: 1em;
      font-weight: bold;
      color: $color-text-light;
      @media screen and (min-width: 992px) {
        color: $color-text-light;
        top: 60px;
        left: -90px;
        font-weight: 400;
        font-size: 1.5em;
      }
    }
    & .title {
      margin-top: 20px;
      text-align: right;
      color: $color-text-light;
      font-weight: 200;
      font-size: 4em;
      @media screen and (min-width: 992px) {
        font-size: 6em;
        margin-top: 5px;
      }
    }
  }
}
.fall.active {
  @include fall-down(1.2s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1s);
}
</style>
