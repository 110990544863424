<template>
  <section class="background">
    <div class="pt-5">
      <h1 id="register">Votre devis cool roof</h1>
    </div>
    <div class="container-fluid devis">
      <div class="block-form">
        <form ref="formData" id="regForm" class="col-6">
          <div class="all-steps" id="all-steps">
            <span
              class="step"
              v-for="(step, index) in steps"
              :key="index"
              :class="{
                active: index === currentTab,
                finish: index < currentTab,
              }"
            >
              <i
                class="fa"
                :class="{
                  [step.icon]: index === currentTab,
                  'fa-check-circle': index < currentTab,
                }"
                :style="{
                  'margin-right': index < steps.length - 1 ? '5px' : '0',
                  'border-radius': index === currentTab ? '50%' : '0',
                  opacity: index === currentTab ? '1' : '0.8',
                }"
              ></i>
            </span>
          </div>

          <div
            class="tab"
            v-for="(step, index) in steps"
            :key="index"
            v-show="index === currentTab"
          >
            <p class="text-white">{{ step.question }}</p>
            <div v-if="step.type === 'text'">
              <input
                type="text"
                :name="step.name"
                :placeholder="step.placeholder"
                maxlength="50"
                v-model="step.answer"
                @input="step.isValid = true"
                @keyup.enter="nextTab"
              />
            </div>
            <div v-else-if="step.type === 'email'">
              <input
                type="email"
                name="email"
                :placeholder="step.placeholder"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                maxlength="60"
                v-model="step.answer"
                @input="step.isValid = true"
                @keyup.enter="nextTab"
              />
            </div>
            <div v-else-if="step.type === 'phone'">
              <input
                type="phone"
                name="phone"
                :placeholder="step.placeholder"
                pattern="^(\+?\d{1,3}[- ]?)?\d{10}$"
                v-model="step.answer"
                @input="step.isValid = true"
                @keyup.enter="nextTab"
              />
            </div>
            <div class="radio-block" v-else-if="step.type === 'radio'">
              <label
                v-for="(option, optionIndex) in step.options"
                :key="optionIndex"
                class="options mx-2"
              >
                <input
                  type="radio"
                  :value="option"
                  name="materiaux"
                  v-model="step.answer"
                  @input="step.isValid = true"
                  @keyup.enter="nextTab"
                />
                <div class="option">
                  {{ option }}
                </div>
              </label>
            </div>

            <div v-else-if="step.type === 'checkbox'">
              <label
                v-for="(option, optionIndex) in step.options"
                :key="optionIndex"
              >
                <input
                  type="checkbox"
                  :value="option"
                  name="materiaux"
                  v-model="step.answer"
                  @change="step.isValid = true"
                  @keyup.enter="nextTab"
                />
                {{ option }}
              </label>
            </div>
          </div>
          <div style="overflow: auto" id="nextprevious">
            <div class="float">
              <span v-if="this.isCaptchaVisible">
                <!--10000000-ffff-ffff-ffff-000000000001-->
                <vue-hcaptcha
                  sitekey="dd93e40f-34be-411f-b55c-68375ab95668"
                  @verify="onVerify"
                  @error="onError"
                  class="vue-hcaptcha"
                ></vue-hcaptcha>
              </span>
              <button
                type="button"
                id="prevBtn"
                @click="prevTab"
                v-show="currentTab > 0"
              >
                <i class="fa fa-angle-double-left"></i> Retour
              </button>
              <button type="button" id="nextBtn" @click="nextTab">
                {{ currentTab >= steps.length - 1 ? "Envoyez" : "Suivant" }}
                <i class="fa fa-angle-double-right"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="container-fluid d-flex justify-content-end py-3">
        <div class="d-flex justify-content-center align-items-end">
          <img
            src="@/assets/brand/white-sigle.svg"
            width="80"
            alt="logo"
            class="p-0"
          />
          <h3 class="text-uppercase">Nubetec</h3>
        </div>
      </div>
    </div>
  </section>
  <span v-if="thanks">
    <div class="container thanks-message">
      <i class="fa fa-check-circle price"></i>
      <h3>Merci</h3>
      <p id="cool-roof-devis-en-ligne">
        Nous avons bien reçu votre demande de devis Cool Roof. Nous vous
        confirmons que celle-ci est en cours de traitement.
      </p>
      <p>
        Nous vous remercions pour votre intérêt et nous mettons tout en œuvre
        pour vous fournir une réponse dans les meilleurs délais.
      </p>
      <span>L'équipe Nubetec</span>
    </div>
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
export default {
  name: "DevisHeader",
  components: { VueHcaptcha },
  data() {
    return {
      isCaptchaVerified: false,
      isCaptchaVisible: false,
      currentTab: 0,
      total: "",
      thanks: false,
      steps: [
        {
          question: "Nom de votre entreprise ou personnel",
          type: "text",
          placeholder: "Nom...",
          name: "name",
          answer: "",
          isValid: false,
          icon: "fa fa-user",
        },
        {
          question: "Votre email",
          type: "email",
          placeholder: "Email...",
          answer: "",
          isValid: false,
          icon: "fa fa-envelope",
        },
        {
          question: "Votre numéro de téléphone",
          type: "phone",
          placeholder: "Numéro...",
          answer: "",
          isValid: false,
          icon: "fa fa-phone",
        },
        /*{
          question: "Selectionnez le type de toiture:",
          type: "radio",
          options: ["Béton", "Taule", "Zinc"],
          answer: "",
          isValid: false,
          icon: "fa fa-home",
        },*/
        {
          question: "Entrez la surface à protéger par cool-roofing en m2:",
          type: "text",
          name: "size",
          placeholder: "Superficie...",
          answer: "",
          isValid: false,
          icon: "fa fa-arrows",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      SendingEmail: "StateSendingEmail",
      EmailSent: "StateEmailSent",
      EmailError: "StateEmailError",
    }),
  },
  methods: {
    ...mapActions(["SendBookingEmail"]),
    prevTab() {
      if (this.currentTab > 0) {
        this.currentTab--;
      }
    },
    nextTab() {
      const currentStep = this.steps[this.currentTab];
      if (currentStep.isValid || this.currentTab >= this.steps.length - 1) {
        if (this.currentTab < this.steps.length - 1) {
          this.currentTab++;
        } else {
          if (this.isCaptchaVerified) {
            this.calculateCost();
            this.redirectToAnchor();
            this.isCaptchaVisible = false;
          } else {
            this.isCaptchaVisible = true;
          }
        }
      }
    },
    onVerify(token, eKey) {
      if (!token && !eKey) {
        alert("Un probléme est survenue lors de la validation du captcha");
      } else {
        this.isCaptchaVerified = true;
      }
    },
    onError: (err) => {
      console.log("Error", err);
    },
    closeModalConfirm() {
      this.modalConfirm = false;
    },
    redirectToAnchor() {
      window.scrollBy({
        top: 500,
        left: 0,
        behavior: "smooth",
      });
    },
    calculateCost() {
      const area = parseFloat(this.steps[3].answer);
      /*const materialCosts = {
        Béton: 20, // Price per square meter for Concrete
        Zinc: 80, // Price per square meter for Zinc
        Taule: 100, // Price per square meter for Copper
      };*/

      let totalPrice = 0;

      //const selectedMaterial = this.steps[3].answer;
      totalPrice = area * 20;
      /*if (selectedMaterial in materialCosts) {
        totalPrice = area * materialCosts[selectedMaterial];
      }*/
      this.total = totalPrice.toFixed(2);
      this.thanks = true;
      let form = new FormData();
      form.append("name", this.steps[0].answer);
      form.append("email", this.steps[1].answer);
      form.append("phone", this.steps[2].answer);
      form.append("size", this.steps[3].answer); //

      this.SendBookingEmail(this.$refs.formData);
      this.modalConfirm = true;
      this.isCaptchaVerified = false;
      this.isCaptchaVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  @include background-focus;
  margin-bottom: -10px;
  @media screen and (max-width: 992px) {
    padding-bottom: 10px;
  }
  & h3 {
    font-weight: 200;
    color: $color-text-light;
    font-size: 2em;
    margin: 0px;
    margin-left: -10px;
  }
}
.devis {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & .block-form {
    display: flex;
    justify-content: space-between;
  }
  & #regForm {
    border: 1px $color-primary outset;
    border-radius: 8px;
    max-width: 95vw;
    min-height: 300px;
    padding: 25px;
    background-color: #000000e3;
    margin-bottom: 20px;
    & input,
    p {
      width: 100%;
      @media screen and (min-width: 992px) {
        width: 90%;
      }
    }
    h6 {
      font-size: 1.5em;
      margin: 0px 0px 40px;
      color: $color-text-light;
    }
  }
}
#nextprevious {
  overflow: auto;
  & .float {
    i {
      font-size: 1em;
    }
  }
}

h1 {
  text-align: center;
  margin-bottom: 70px;
  font-weight: 130;
  color: $color-text-light;
}
form {
  width: 600px;
}
input,
select,
label {
  padding: 10px;
  font-size: 1em;
  border-radius: 10px;
}

input[type="radio"] {
  border: none !important;
}

input[type="number"] {
  width: 100%;
}

input.invalid,
select.invalid {
  border: 1px solid red;
}

i {
  font-size: 2em;
  margin-right: 5px;
}
i:not(.fa-check-circle) {
  color: $color-primary;
}
button {
  background-color: #181818;
  color: whitesmoke;
  border: 0.5px outset $color-primary;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
  margin: 10px 2px;
}

button:hover {
  opacity: 0.8;
}

button:focus {
  outline: none !important;
}
.finish {
  color: green !important;
}
.all-steps {
  & div {
    width: 200px;
  }
}
.thanks-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 40px;
  & h2,
  p,
  span {
    text-align: center;
  }
  .price {
    font-size: 3em;
    margin: 40px 0px;
    color: $color-primary;
  }
}
.radio-block {
  display: flex;
  justify-content: space-around;
}
.option {
  color: #559fad;
  font-size: 1em;
  margin: auto;
  border: none;
}
.vue-hcaptcha {
  margin: 10px 5px;
}
</style>
