import axios from "axios";

const state = {
  allCategory: null,
  category: null,
};

const getters = {
  StateAllCategory: (state) => state.allCategory,
  StateCategory: (state) => state.category,
};

const actions = {
  async GetAllCategory({ commit }) {
    let response = await axios.get("categories");
    commit("setAllCategory", response.data["hydra:member"]);
  },

  async GetCategory({ commit }, categoryId) {
    let response = await axios.get("categories/" + categoryId);
    commit("setCategory", response.data["hydra:member"]);
  },

  async CreateCategory({ dispatch }, form) {
    await axios.post("categories", form);
    return await dispatch("GetAllCategory");
  },

  async UpDateCategory({ dispatch }, category) {
    let form = new FormData();
    form.append("name", category.name);
    form = Object.fromEntries(form.entries());
    await axios.put("categories/" + category.id, form);
    return await dispatch("GetAllCategory");
  },

  async DeleteCategory({ dispatch }, categoryId) {
    await axios.delete("categories/" + categoryId);
    return await dispatch("GetAllCategory");
  },
};

const mutations = {
  setAllCategory(state, categories) {
    state.allCategory = categories;
  },
  setCategory(state, category) {
    state.category = category;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
