<template>
  <div class="services">
    <HeaderBase
      anteTitle="nubetec présente le :"
      title="cool-roof"
      :backgroundImage="require('@/assets/pictures/nubetec-roof.webp')"
    />
    <ServicesFirst />
    <ServicesSecond />
    <ServicesThird />
    <ServicesLast />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderBase from "@/components/HeaderBase.vue";
import ServicesFirst from "@/components/services/ServicesFirst.vue";
import ServicesSecond from "@/components/services/ServicesSecond.vue";
import ServicesThird from "@/components/services/ServicesThird.vue";
import ServicesLast from "@/components/services/ServicesLast.vue";
export default {
  components: {
    HeaderBase,
    ServicesFirst,
    ServicesSecond,
    ServicesThird,
    ServicesLast,
  },
  setup() {
    useMeta({
      title: "Le Cool Roof",
      description: [
        {
          content:
            "Découvrez nos services de Cool Roof de pointe. Des solutions sur mesure pour une toiture plus efficace et respectueuse de l'environnement. Contactez-nous pour bénéficier de notre expertise et transformer votre toit en une véritable innovation énergétique !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr/services",
        },
      ],
    });
  },
};
</script>
