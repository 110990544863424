<template>
  <div class="home">
    <HomeHeader />
    <UnderHeader />
    <VideoHome />
    <UnderRow />
    <HomeService />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHeader from "@/components/home/HomeHeader.vue";
import UnderHeader from "@/components/home/UnderHeader.vue";
import UnderRow from "@/components/home/UnderRow.vue";
import VideoHome from "@/components/home/VideoHome.vue";
import HomeService from "@/components/home/HomeService.vue";
import { useMeta } from "vue-meta";
export default {
  components: {
    HomeHeader,
    UnderHeader,
    UnderRow,
    VideoHome,
    HomeService,
  },
  setup() {
    useMeta({
      title: "Cool Roof",
      description: [
        {
          content:
            "Nubetec: Pionnier du Cool Roof pour toitures éco-responsables. Réduisez la chaleur, économisez l'énergie et contribuez à un avenir durable. Contactez-nous dès maintenant !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr",
        },
      ],
    });
  },
};
</script>
