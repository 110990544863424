<template>
  <div class="speech">
    <p class="ants-title">Le Cool-Roofing par</p>
    <p class="title">NUBETEC</p>
  </div>
</template>

<script>
export default {
  name: "SpeechHeaderHome",
};
</script>

<style lang="scss" scoped>
.speech {
  margin: auto auto;
  & .ants-title {
    position: relative;
    top: 45px;
    left: 0px;
    text-align: start;
    font-size: 1em;
    font-weight: bold;
    color: $color-text-light;
    @media screen and (min-width: 992px) {
      top: 45px;
      left: -90px;
      font-weight: 400;
      font-size: 1.5em;
    }
  }
  & .title {
    margin-top: 20px;
    text-align: right;
    color: $color-text-light;
    font-weight: 200;
    font-size: 4em;
    @media screen and (min-width: 992px) {
      font-size: 6em;
      margin-top: 5px;
    }
  }
}
</style>
