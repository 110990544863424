const state = {
  read: false,
  //posts: null,
};

const getters = {
  StateRead: (state) => state.read,
};

const actions = {
  async readed({ commit }) {
    await commit("setRead", true);
  },
};

const mutations = {
  setRead(state, read) {
    state.read = read;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
