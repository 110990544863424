<template>
  <div class="block">
    <div class="container animed-text">
      <div class="upper">COOL ROOF</div>
      <div class="lower">COOL ROOF</div>
      <div class="inside">Découvrez notre technologie</div>
    </div>
    <RowIcons />
  </div>
</template>

<script>
import RowIcons from "@/components/home/RowIcons.vue";
export default {
  name: "UnderHeader",
  components: { RowIcons },
};
</script>

<style lang="scss" scoped>
.block {
  background-color: #569fae;
  padding: 10px 0px 20px;
}

.block:hover .upper {
  top: -45px;
}

.block:hover .lower {
  top: 30px;
}

.block:hover .inside {
  opacity: 1;
}

.animed-text {
  width: 100%;
  height: 1.15em;
  font-size: 4rem;
  font-family: sans-serif;
  position: relative;
  margin-bottom: 80px;
}

.animed-text div {
  position: absolute;
  left: 0;
  text-transform: uppercase;
  width: 100%;
  display: block;
  text-align: center;
  transition: top 1.5s ease-in-out;
}

.upper {
  top: 0;
  height: 52.5%;
  color: #fff;
  overflow: hidden;
  z-index: 3;
  background-color: #569fae;
}

.lower {
  bottom: 0;
  height: 100%;
  background: linear-gradient(180deg, #569fae 52.5%, #fff 52.5%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 1;
}

.inside {
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  text-align: center;
  z-index: 2;
  font-size: 1rem;
  color: $color-text;
  font-weight: 500;
}

@media (max-width: 992px) {
  .animed-text {
    font-size: 2.5rem;
  }

  .inside {
    font-size: 0.75rem;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 320px) {
  .animed-text {
    font-size: 2rem;
  }

  .inside {
    font-size: 0.5rem;
  }
}
//gg
</style>
