<template>
  <section class="underRow">
    <div class="header container">
      <p>Avec</p>
      <h3>NUBETEC</h3>
      <p>
        Améliorez votre <strong>confort thermique</strong> tout en
        <strong>économisant sur vos factures d'électricité</strong>.<br />
        Le procédé <strong>COOL ROOF</strong>
        <strong> "toiture fraîche"</strong> permet de
        <strong>rejeter 95% du rayonnement solaire</strong> et
        <strong>diminue jusqu'à 6°C la température du bâtiment</strong>.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "UnderRow",
};
</script>

<style lang="scss" scoped>
.underRow {
  margin: 130px 0px 100px;
  padding: 5px;
  background-color: #569fae;
  @media screen and (min-width: 992px) {
    padding: 30px 50px;
  }
  & h3 {
    font-weight: 100;
    font-size: 3em;
    color: $color-text;
    line-height: 0.2;
  }
  & .header {
    text-align: center;
    padding: 15px;
    & p:first-of-type {
      margin-top: 2px;
    }
    & p {
      color: $color-text-light;
      text-align: center;
      margin-top: 30px;
    }
  }
}

.cyan {
  border-top: 3px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 3px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 3px solid $color-primary;
}
.yellow {
  border-top: 3px solid hsl(59, 91%, 65%);
}

@media (min-width: 992px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-down {
    position: relative;
    top: 150px;
  }
  .box {
    width: 30%;
  }
}
</style>
