<template>
  <div class="contact">
    <HeaderBase
      anteTitle="Votre solution"
      title="cool-roof"
      :backgroundImage="require('@/assets/pictures/cool-roof.webp')"
    />
    <ContactForm />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import HeaderBase from "@/components/HeaderBase.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
export default {
  components: {
    HeaderBase,
    ContactForm,
  },
  setup() {
    useMeta({
      title: "Votre contact Cool Roof",
      description: [
        {
          content:
            "Contactez-nous dès maintenant pour tous vos besoins en Cool Roof. Notre équipe d'experts est prête à vous aider et à répondre à toutes vos questions. Ensemble, créons une toiture éco-responsable et durable !",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.nubetec.fr/contact",
        },
      ],
    });
  },
};
</script>
