import axios from "axios";

const state = {
  allTag: null,
  tag: null,
};

const getters = {
  StateAllTag: (state) => state.allTag,
  StateTag: (state) => state.tag,
};

const actions = {
  async GetAllTag({ commit }) {
    let response = await axios.get("tags");
    commit("setAllTag", response.data["hydra:member"]);
  },

  async GetTag({ commit }, tagId) {
    let response = await axios.get("tags/" + tagId);
    commit("setTag", response.data["hydra:member"]);
  },

  async CreateTag({ dispatch }, form) {
    await axios.post("tags", form);
    return await dispatch("GetAllTag");
  },

  async UpDateTag({ dispatch }, tag) {
    await axios.put("tags/" + tag.id, tag);
    return await dispatch("GetAllTag");
  },

  async DeleteTag({ dispatch }, tagId) {
    await axios.delete("tags/" + tagId);
    return await dispatch("GetAllTag");
  },
};

const mutations = {
  setAllTag(state, tags) {
    state.allTag = tags;
  },
  setTag(state, tag) {
    state.tag = tag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
