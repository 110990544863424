<template>
  <div class="container my-5">
    <!--Section: Content-->
    <section class="article-section">
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div
          class="card-group col-lg-4 col-12 mb-lg-0 mb-4"
          v-for="post in posts"
          :key="post.id"
        >
          <!-- Card -->
          <div class="card hoverable m-4" @click="showPost(post.id)">
            <!-- Card image -->
            <div class="card-title d-flex justify-content-center">
              <p>{{ post.title }}</p>
            </div>
            <img
              class="card-img-top"
              :src="'https://api-blog.nubetec.fr' + post.img.contentUrl"
              alt="photo-article"
            />
            <!-- Card content -->
            <div class="card-body">
              <!-- Title -->
              <div class="label-card">
                <p>Catégorie :</p>
                <p>tags:</p>

                <!--<div>
                  <div v-if="post.updatedAt !== '' && post.updatedAt !== null">
                    <p>modifié le:</p>
                  </div>
                  <div v-else>
                    <p>publié le:</p>
                  </div>
                </div>-->
              </div>
              <div class="card-body__header">
                <div>
                  <p class="card-category text-uppercase font-small">
                    {{ post.category.name }}
                  </p>
                </div>
                <div class="tags">
                  <div class="tag" v-for="tag in post.tags" :key="tag.id">
                    <strong>{{ tag.label }}</strong>
                  </div>
                </div>
              </div>
            </div>

            <!-- Card -->
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
export default {
  name: "AuthorArticleSelect",
  props: {
    posts: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return formatDate(this.post.updatedAt);
    },
    ...mapGetters({
      Posts: "StatePosts",
      User: "StateUser",
      Post: "StatePost",
    }),
  },
  methods: {
    ...mapActions(["GetPosts", "GetPost"]),
    async showPost(id) {
      try {
        await this.GetPost(id);
        this.$router.push({
          name: "edit",
          params: { slug: this.Post.slug },
        });
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.article-section {
  margin-bottom: 200px;
}
.label-card {
  font-size: 0.6em;
  display: flex;
  justify-content: space-between;
  color: $color-text-light;
}
p {
  margin-bottom: 0.3px;
}
.card {
  border-radius: 10px 10px 2px 2px;
  @include hover-effect-grown;
  cursor: pointer;
  background-color: $color-primary;
  .card-title {
    background-color: $color-primary;
    color: $color-text;
    font-size: 1em;
    padding: 5px;
    margin: 0px;
    text-align: center;
    border-radius: 10px 10px 2px 2px;
  }
  .card-img-top {
    height: 200px;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
  .card-body {
    background-color: $color-primary;
    border-top: 0.5px $color-primary outset;
    color: $color-text;
    padding: 10px;
    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 0.5px;
      .card-category {
        font-size: 0.7em;
        margin-top: 3px;
        margin-right: 4px;
        padding: 0.5px 5px;
        border: 0.5px $color-primary outset;
        border-radius: 3px 9px;
        @media screen and (min-width: 992px) {
          background-color: $color-lighter;
          color: $color-text-grey;
        }
      }
      .card-date {
        font-size: 0.5em;
        @media screen and (max-width: 992px) {
          @include text-gradient(#f1eeee, #f27316);
        }
        @media screen and (min-width: 992px) {
          color: $color-text-grey;
        }
      }
    }
    .tags {
      font-size: 0.6em;
      display: flex;
      margin-top: 3px;
      .tag {
        display: block;
        margin-right: 4px;
        padding: 0.5px 4px;
        border: 0.5px $color-primary outset;
        border-radius: 9px 3px;
        background-color: $color-lighter;
        color: $color-primary;
        font-weight: bolder;
        @media screen and (min-width: 992px) {
          color: $color-text-grey;
        }
      }
    }
  }
}
</style>
