<template>
  <div class="container-fluid">
    <div class="container first-block mt-5">
      <section class="dark-grey-text">
        <div class="header-first">
          <h2 class="text-left font-weight-bold mb-4 pb-2">
            Le cool-roof : c'est quoi ?
          </h2>
          <p class="text-left mx-auto mb-5">
            Le <strong>cool-roof</strong>, une technique innovante vous
            permettant d'<strong
              >économiser de 20% à 40% de la consommation énergétique</strong
            >
            de votre système de climatisation par l’application d’une
            <strong>peinture réflective</strong> blanche sur les toitures.<br />Cette
            peinture va <strong>réfléchir les rayons du soleil</strong> pour
            <strong>diminuer les surchauffes</strong> et vous permettre de
            <strong>baisser la température dans l'enceinte du bâtiment</strong>.
          </p>
        </div>
        <div class="row first-block__row">
          <div class="col-11 col-lg-6 slide-left">
            <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
              <video
                playsinline="playsinline"
                autoplay="autoplay"
                muted="muted"
                loop="loop"
              >
                <source
                  src="@/assets/video/reflectance-sun.mp4"
                  type="video/mp4"
                />
                <track
                  src="reflectance_soleil_sur_toiture"
                  kind="captions"
                  srclang="fr"
                  label="french_captions"
                />
                <track
                  src="sun_reflect_on _roof"
                  kind="captions"
                  srclang="en"
                  label="english_captions"
                />
              </video>
            </div>
          </div>
          <div class="col-11 col-lg-5 right-block mx-auto slide-right">
            <div>
              <h3 class="mb-3">Pourquoi renvoyer le rayonnement solaire ?</h3>
              <p>
                En été, la <strong>température d'une toiture</strong> peut
                atteindre 85°C en surface avec pour conséquence une augmentation
                de la chaleur dans l'enceinte du bâtiment dont la toiture
                n'aurait pas été protégée par le procédé
                <strong>COOL-ROOF</strong> de chez Nubetec.
              </p>
            </div>
            <div class="temperature-scale fall">
              <div class="temperature-label">85°C</div>
              <div class="arrowLine">
                <div class="hr"></div>
                <div class="arrow"></div>
              </div>
              <div class="temperature-label">40°C</div>
            </div>
          </div>
        </div>
        <hr class="my-5" />
      </section>
    </div>
    <div class="container second-block mt-lg-5">
      <section class="dark-grey-text">
        <div class="reverse row align-items-center">
          <div class="first-reverse col-lg-5 slide-left">
            <h3 class="mb-3">Économie d'énergie</h3>

            <p>
              <strong
                >Économisez jusqu'à 40% sur vos factures d'électricité</strong
              >, tout en réduisant la
              <strong>température intérieure du bâtiment</strong> jusqu'à 6°C.
            </p>
            <p>
              Le <strong>cool-roofing</strong> moins coûteux et sans contrainte.
            </p>

            <div class="rightBtn-gradient">
              <button
                @click.prevent="moveTo(eco)"
                class="btn btn-success btn-md btn-rounded btn-gradient mx-0"
                name="btn-more"
                type="submit"
              >
                En savoir plus &raquo;
              </button>
            </div>
          </div>

          <div class="second-reverse col-lg-6 p-2">
            <div
              class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-right"
            >
              <img
                src="@/assets/pictures/poteaux_graphique.webp"
                alt="graphic-economy-energy"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoHome",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left");
    });
  },
  methods: {
    moveTo(anchorId) {
      this.$router
        .push({ path: "/cool-roof-france", hash: anchorId })
        .then(() => {
          // Faire défiler jusqu'à l'ancre après le changement de page
          this.$nextTick(() => {
            const anchor = document.getElementById(anchorId);
            if (anchor) {
              anchor.scrollIntoView({ behavior: "smooth" });
            }
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.first-block {
  padding: 5px 0px 5px;
  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: center;
    }
    & video {
      width: 94%;
      margin: auto;
      margin-bottom: 25px;
      @media screen and (min-width: 992px) {
        width: 100%;
        height: 500px;
        margin-bottom: 0px;
      }
    }
    & .right-block {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      & .temperature-scale {
        width: 100%;
        background: linear-gradient(to right, red, yellow, green);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: 50px;
        padding: 5px 10px;
        border-radius: 2px;
        @media screen and (min-width: 992px) {
          margin-top: 100px;
        }
        & .temperature-label {
          margin: 5px;
          font-size: 14px;
          color: $color-text-light;
        }
        & .arrowLine {
          display: flex;
          justify-content: center;
          align-items: center;
          & .arrow {
            content: "";
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #fff;
            left: 50%;
            transform: translateX(-50%);
          }

          & .hr {
            width: 50px;
            height: 3px;
            background-color: $background;
          }
        }
      }
    }
  }
}

.second-block {
  & img {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
  }
  & .reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.wrapper {
  position: absolute;
  top: 13%;
  width: 100vw;
  height: 70vh;
  padding: 10px 20px;
}
.fall.active {
  @include fall-down(1.2s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1s);
}
</style>
