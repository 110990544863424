import axios from "axios";

const state = {
  profile: null,
};

const getters = {
  StateProfile: (state) => state.profile,
};

const actions = {
  async GetProfile({ commit }, profileId) {
    let response = await axios.get("users/" + profileId);
    commit("setProfile", response.data);
  },

  async UpDateProfile({ dispatch }, profile) {
    let form = new FormData();
    form.append("email", profile.email);
    form.append("lastName", profile.lastName);
    form.append("firstName", profile.firstName);
    form.append("pseudo", profile.pseudo);
    form.append("birthday", profile.birthday);
    form = Object.fromEntries(form.entries());
    await axios.put("users/" + profile.id, form);
    return await dispatch.state.profile;
  },

  async UpDatePassword({ dispatch }, profile) {
    let form = new FormData();
    form.append("password", profile.password);
    form.append("newPassword", profile.newPassword);
    form = Object.fromEntries(form.entries());
    await axios.put("users/" + profile.id, form);
    return await dispatch.state.profile;
  },

  async AddRole({ dispatch }, profile) {
    /*let form = new FormData();
    form.append("roles[]", profile.roles);
    form = Object.fromEntries(form.entries());*/
    const data = {
      roles: profile.roles, // Convertir le tableau de rôles en une chaîne JSON
    };
    await axios.put("users/" + profile.id, data);
    return await dispatch.state.profile;
  },

  async CreateAvatar({ dispatch }, form) {
    const profileId = form.get("profileId");
    await axios
      .post("avatar_media", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (response) => {
        let userForm = new FormData();
        //const resp = JSON.stringify(response.data["@id"]);
        //const resp = JSON.stringify(response.data["@id"]);
        userForm.append("avatar", response.data["@id"]);
        userForm = Object.fromEntries(userForm.entries());
        await axios.put("users/" + profileId, userForm);
      })
      .then(async () => {
        await dispatch("GetProfile", profileId);
      });

    return await dispatch.state.profile;
  },

  async DeleteProfile({ dispatch }, profileId) {
    await axios.delete("users/" + profileId);
    return await dispatch.state.profile;
  },

  async DeleteAvatar({ dispatch }) {
    let userForm = new FormData();
    userForm.append("avatar", "api/avatar_media/1");
    userForm = Object.fromEntries(userForm.entries());
    await axios.put("users/" + 1, userForm);
    return await dispatch.state.profile;
  },
};

const mutations = {
  setProfile(state, user) {
    state.profile = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
