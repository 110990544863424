<template>
  <PostRead :post="Post" />
  <AuthorCard :author="Post.author" />

  <!--<div>
    <form @submit.prevent="submit(Post.id)">
      <div>
        <label for="content">message:</label>
        <input type="textarea" name="content" v-model="form.content" />
      </div>
      <button type="submit">Submit</button>
    </form>
    <p v-if="showError" id="error">Username or Password is incorrect</p>
  </div>
  <div v-for="comment in Post.comments" :key="comment.id" class="px-5">
    <span v-if="isLoggedIn && comment.user.id == User.id && formVisible">
      <form @submit.prevent="updateForm(comment)">
        <div>
          <input type="textarea" name="content" v-model="comment.content" />
        </div>
        <button type="submit">Submit</button>
      </form>
    </span>
    <span v-else>
      <p>{{ comment.content }}</p>
      <p>{{ comment.user.pseudo }}</p>
    </span>
    <span v-if="isLoggedIn && comment.user.id == User.id">
      <p>{{ comment.id }}</p>
      <button @click="update">modifier</button>
      <button @click="remove(comment.id)">supprimer</button>
    </span>
    <div class="my-5"></div>
  </div>-->
</template>

<script>
import PostRead from "@/components/blog/PostRead.vue";
import AuthorCard from "@/components/blog/AuthorCard.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PostView",
  components: { PostRead, AuthorCard },
  created() {
    const slug = this.$route.params.slug;
    this.$store.dispatch("GetPostBySlug", slug);
  },
  data() {
    return {
      form: {
        content: "",
        article: "",
      },
      formVisible: false,
      showError: false,
    };
  },
  computed: {
    ...mapGetters({
      Token: "StateToken",
      User: "StateUser",
      Post: "StatePost",
    }),
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    ...mapActions([
      "GetPosts",
      "GetPost",
      "GetPostBySlug",
      "CreateComment",
      "UpDateComment",
      "DeleteComment",
    ]),
    async submit(articleId) {
      this.form.article = "api/articles/" + articleId;
      let Comment = new FormData();
      Comment.append("content", this.form.content);
      Comment.append("article", this.form.article);
      Comment = Object.fromEntries(Comment.entries());
      try {
        const article = this.$store.getters.StatePost;
        await this.CreateComment(Comment).then(this.GetPost(article.id));
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async update() {
      if (!this.formVisible) {
        this.formVisible = true;
      } else {
        this.formVisible = false;
      }
      return this.formVisible;
    },
    async updateForm(comment) {
      try {
        const article = this.$store.getters.StatePost;
        await this.UpDateComment(comment).then(this.GetPost(article.id));
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async remove(commentId) {
      try {
        let article = this.$store.getters.StatePost;
        await this.DeleteComment(commentId).then(this.GetPost(article.id));
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>
