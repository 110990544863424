<template>
  <div class="container p-lg-5 z-depth-1">
    <section class="dark-grey-text text-center">
      <p class="text-center title-blog jaapokki pt-5">
        Écoénergie<br /><span>Nubetec</span>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "HeroBlog",
  components: {},
};
</script>

<style lang="scss" scoped>
.title-blog {
  font-size: 3em;
  color: $color-primary;
  & span {
    font-weight: 100;
    color: $color-darker;
  }
}
img {
  width: 100px;
}
.hide {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
