<template>
  <section class="update-section">
    <p class="title">Tags</p>

    <div class="list">
      <div v-for="tag in AllTag" :key="tag.id" class="px-5">
        <input
          v-model="selectedTag"
          @input="showTag(tag.id)"
          type="radio"
          :value="tag"
          name="radioTag"
          :id="tag.id"
          class="input-hidden"
        />
        <label :for="tag.id">
          <p>{{ tag.label }}</p>
        </label>
      </div>
    </div>
    <div class="container">
      <div class="text-center">
        <p class="text-muted mb-0">Sélection</p>
      </div>
      <div class="container">
        <input
          type="text"
          v-model="form.label"
          :placeholder="selectedTag.label"
        />
      </div>
      <div class="d-flex justify-content-center my-5 pt-3">
        <button @click="updateTag(selectedTag.id)" class="btn btn-valid">
          Modifier
        </button>
        <button @click="deleteTag(selectedTag.id)" class="btn btn-delete ms-1">
          Supprimer
        </button>
      </div>
    </div>
    <hr />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MalaTags",
  components: {},
  data() {
    return {
      selectedTag: "",
      form: {
        label: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      AllTag: "StateAllTag",
      Tag: "StateTag",
    }),
  },
  methods: {
    ...mapActions(["GetTag", "UpDateTag", "DeleteTag"]),
    async showTag(id) {
      try {
        await this.GetTag(id);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    async updateTag(tagId) {
      try {
        this.form.id = tagId;
        //this.form.name = this.$ref.inputCat;
        await this.UpDateTag(this.form);
        this.form.label = "";
      } catch (error) {
        this.showError = true;
      }
    },
    async deleteTag(tagId) {
      try {
        await this.DeleteTag(tagId);
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.update-section {
  padding: 50px 0px 200px 0px;
  & .title {
    font-size: 1.8em;
    text-align: center;
    margin: 50px 0px;
  }
  & .list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    width: 90vw;
    overflow: auto;
    padding: 10px 20px;
    background-color: $color-darker;
    border-radius: 10px;
    border: 1px outset $color-darker;
    margin: 10px auto 40px auto;
    & .input-hidden {
      position: absolute;
      left: -9999px;
    }

    & input[type="radio"]:checked + label > p {
      border: 0.1px outset $color-primary;
      box-shadow: 0 0 3px 3px $color-primary;
    }

    /* Stuff after this is only to make things more pretty */
    & input[type="radio"] + label > p {
      border: 1px outset #444;
      transition: 500ms all;
    }
    & label {
      & p {
        padding: 5px 10px;
        border: 0.1px inset $color-darker;
        border-radius: 10px;
        font-size: 1em;
        color: $color-text-light;
        text-align: center;
      }
    }
  }
}

.btn-valid {
  width: fit-content;
  background-color: green;
  color: $color-text-light;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    scale: 96%;
  }
}
.btn-delete {
  width: fit-content;
  background-color: red;
  color: $color-text-light;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    scale: 96%;
  }
}
</style>
