import axios from "axios";

const state = {
  user: null,
  token: null,
  //posts: null,
};

const getters = {
  // isAuthenticated: (state) => !!state.user,
  isAuthenticated: (state) => !!state.token,
  StateToken: (state) => state.token,
  StateUser: (state) => state.user,
  //StatePosts: (state) => state.posts,
};

const actions = {
  async Register({ dispatch }, form) {
    await axios.post("users", form);
    let UserForm = new FormData();
    UserForm.append("email", form.email);
    UserForm.append("password", form.password);
    await dispatch("LogIn", UserForm);
  },

  async LogIn({ commit }, user) {
    user = Object.fromEntries(user.entries());
    await axios.post("login", user).then((response) => {
      //localStorage.setItem("accessToken", response.data.token);
      commit("setToken", response.data.token);
      commit("setUser", response.data.user);
    });
  },

  async LogOut({ commit }) {
    localStorage.removeItem("accessToken");
    commit("setToken", null);
    commit("setUser", null);
    commit("logout");
  },
  /*async CreatePost({ dispatch }, post) {
    await axios.post("articles", post);
    return await dispatch("GetPosts");
  },

  async GetPosts({ commit }) {
    let response = await axios.get("articles");
    commit("setPosts", response.data);
  },*/
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    state.user = user;
  },
  logout(state, token) {
    state.token = token;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
