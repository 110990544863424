<template>
  <div class="container mt-5">
    <!--Section: Content-->
    <section id="ilot" class="dark-grey-text">
      <!-- Grid row -->
      <div class="block-rown">
        <!-- Grid column -->
        <div class="col-lg-7">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 slide-left">
            <video
              playsinline="playsinline"
              autoplay="autoplay"
              muted="muted"
              loop="loop"
            >
              <source
                src="@/assets/video/ilot-chaleur-urbain.mp4"
                type="video/mp4"
              />
              <track
                src="ilot_chaleur_urbain_effet"
                kind="captions"
                srclang="fr"
                label="french_captions"
              />
              <track
                src="heat_island_effect"
                kind="captions"
                srclang="en"
                label="english_captions"
              />
            </video>

            <a>
              <div class="mask rgba-white-slight"></div>
            </a>
          </div>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="block-text col-lg-5 slide-right">
          <!-- Post title -->
          <h3 class="mb-3">Îlot de chaleur urbain</h3>
          <!-- Excerpt -->
          <p>
            L'utilisation des climatisations pendant les fortes chaleurs de
            l'été contribue à faire augmenter la température des villes.
          </p>
          <p>
            Un climatiseur va rejeter l'air chaud d'un bâtiment vers
            l'extérieur, il va donc participer à la formation de l'<strong
              >îlot de chaleur urbain</strong
            >.
          </p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <div class="mt-5 fall">
        <h3 class="mb-3">Le rafraîchissement passif</h3>
        <!-- Section description -->
        <p class="text-left mx-auto w-responsive mb-5">
          Le <strong>cool-roof</strong> fait partie des
          <strong>systèmes de rafraîchissement passif</strong>. Il permet de
          diminuer la <strong>température de votre bâtiment</strong> et de
          <strong>réduire l’impact environnemental</strong>.<br />Avec
          l’application de la <strong>peinture réflective</strong> le toit est
          protégé des surchauffes, l’intérieur du bâtiment est ainsi rafraichi
          sans utiliser d’énergie.
        </p>
      </div>
    </section>
    <!--Section: Content-->
  </div>
  <!--Section: Content-->
</template>

<script>
export default {
  name: "ServicesLast",
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".fall", ".slide-right", ".slide-left");
    });
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  max-height: 462px;
}

.temperature-scale {
  width: 100%;
  background: linear-gradient(to right, red, yellow, green);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.temperature-label {
  margin: 5px;
  font-size: 14px;
  color: $color-text-light;
}
.arrowLine {
  display: flex;
  justify-content: center;
  align-items: center;
  & .arrow {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
    left: 50%;
    transform: translateX(-50%);
  }

  & .hr {
    width: 50px;
    height: 3px;
    background-color: $background;
  }
}

video,
img {
  width: 500px;
  max-width: 100%;
}
.block-rown {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.wrapper {
  position: absolute;
  top: 13%;
  width: 100vw;
  height: 70vh;
  padding: 10px 20px;
}
.fall.active {
  @include fall-down(1.2s);
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1s);
}
</style>
