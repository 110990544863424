import axios from "axios";

const state = {
  comment: null,
};

const getters = {
  StateComment: (state) => state.comment,
};

const actions = {
  async GetComment({ commit }, commentId) {
    let response = await axios.get("comments" + commentId);
    commit("setComment", response.data["hydra:member"]);
  },

  async CreateComment({ dispatch }, form) {
    await axios.post("comments", form);
    return await dispatch.state.comment;
  },

  async UpDateComment({ dispatch }, comment) {
    let form = new FormData();
    form.append("content", comment.content);
    form = Object.fromEntries(form.entries());
    await axios.put("comments/" + comment.id, form);
    return await dispatch.state.comment;
  },

  async DeleteComment({ dispatch }, commentId) {
    await axios.delete("comments/" + commentId);
    return await dispatch.state.comment;
  },
};

const mutations = {
  setComment(state, post) {
    state.comment = post;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
