<template>
  <div>
    <div class="video-header">
      <video
        playsinline="playsinline"
        autoplay="none"
        muted="muted"
        loop="none"
      >
        <source src="@/assets/video/cool-roofing-header.mp4" type="video/mp4" />
        <track
          src="toit_blanc_proteger_par_cool_roofing"
          kind="captions"
          srclang="fr"
          label="french_captions"
        />
        <track
          src="white_roof_protected_by_cool_roofing"
          kind="captions"
          srclang="en"
          label="english_captions"
        />
      </video>
    </div>
    <div class="wrapper d-flex justify-content-around align-items-start">
      <SpeechHeaderHome />
    </div>
  </div>
</template>

<script>
import SpeechHeaderHome from "../partials/SpeechHeaderHome.vue";

export default { components: { SpeechHeaderHome } };
</script>

<style lang="scss" scoped>
.video-header {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 400px;
  overflow: hidden;
  background-color: #569fae;
  @media screen and (min-width: 992px) {
    width: 100%;
    height: 90vh;
  }
  & video {
    position: absolute;
    top: 79%;
    left: 50%;
    transform: translate(-50%, -90%);
    min-width: 100%;
    min-height: 100%;
  }
}

.wrapper {
  position: absolute;
  top: 90px;
  width: 100%;
  height: 50vh;
  padding: 10px 20px;
  @media screen and (min-width: 992px) {
    top: 60px;
    height: 90vh;
  }
}
</style>
