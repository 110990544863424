import axios from "axios";

const state = {
  allPostMedia: null,
};

const getters = {
  StateAllPostMedia: (state) => state.allPostMedia,
};

const actions = {
  async GetAllPostMedia({ commit }) {
    let response = await axios.get("media_objects");
    commit("setAllPostMedia", response.data["hydra:member"]);
  },

  async CreatePostMedia({ dispatch }, form) {
    await axios.post("media_objects", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return await dispatch("GetAllPostMedia");
  },

  async DeletePostMedia({ dispatch }, mediaId) {
    await axios.delete(mediaId);
    return await dispatch("GetAllPostMedia");
  },
};

const mutations = {
  setAllPostMedia(state, media_object) {
    state.allPostMedia = media_object;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
