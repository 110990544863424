<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Nubetec` : `Nubetec`
    }}</template>
  </metainfo>

  <NavBase />
  <!--<NavBlog />-->
  <router-view />
  <LegalBar />
  <FooterBase />
</template>

<script>
// @ is an alias to /src
import NavBase from "@/components/NavBase.vue";
//import NavBlog from "@/components/NavBlog.vue";
import LegalBar from "@/components/plugins/LegalBar.vue";
import FooterBase from "@/components/FooterBase.vue";
import { useMeta } from "vue-meta";

export default {
  components: { NavBase, FooterBase, LegalBar },
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "fr" },
      description: [
        {
          content:
            "Nubetec : Spécialiste du Cool Roof pour des toitures plus écologiques et économes en énergie. Découvrez nos solutions cool roof innovantes pour réduire la chaleur excessive, économiser sur la climatisation et contribuer à un avenir durable. Contactez-nous dès maintenant !",
        },
      ],
    });
  },
};
</script>

<style lang="scss"></style>
